import React, { useState } from "react";
import "./ToastController.css"; // Hier liegt die Animation



const ToastController = React.forwardRef((props, ref) => {
    const [toasts, setToasts] = useState([]);

    const sendMessage = (text, error=false) => {
        const id = Date.now(); // Einzigartige ID für den Toast
        setToasts((prevToasts) => [...prevToasts, { id, text, error }]);
        // Entferne den Toast nach 3 Sekunden
        setTimeout(() => {
            setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
        }, 5000);
    };

    // Exponiere die Methode "sendToastMessage" durch das Ref
    React.useImperativeHandle(ref, () => ({
        sendErrorMessage: () => sendMessage("Vorgang konnte nicht durchgeführt werden",true),
        sendToastMessage: (text, error=false) => sendMessage(text, error)
    }));

    return (
        <div
            className="toast-container position-fixed bottom-0 end-0 p-3"
            style={{ zIndex: 1055 }}
        >
            {toasts.map((toast) => (
                <div
                    key={toast.id}
                    className={toast.error?"toast show fly-in error":"toast show fly-in"}
                    role="alert"
                    aria-live="assertive"
                    aria-atomic="true"
                >
                    <div className="toast-body">{toast.text}</div>
                </div>
            ))}
        </div>
    );
});

export default ToastController;