import React, {Component} from 'react';


import config from "../../config";


import './ProjectPage.css'
import ProjectEditor from "../../components/ProjectEditor";
import LoadingScreen from "../../includes/LoadingScreen";
import {addSessionTokenToRequestHeader} from "../../services/fetchInterceptor.service";
import WorksheetViewComponent from "../../components/WorksheetViewComponent";
import APIController from "../../controller/APIController";
class ProjectPage extends Component {

    constructor(props) {
        super(props);
        this.instructorMode = props.instructorMode;
        this.state = {
            isFetching: true,
            project : null,
            projectId: props.projectId,
            instructorMode: props.instructorMode,
            editMode: props.editMode,
            readOnlyMode: true
        };
    }

    async componentDidMount() {
        try {
            const projectId = encodeURIComponent(this.state.projectId);
            const params = new URLSearchParams({
                projectId: projectId
            });
            addSessionTokenToRequestHeader();

            let allTopics = await APIController.getAllTopics();
            allTopics = allTopics.filter(t=>!t._id.endsWith("1"));

            let response = await fetch(config.API_HOST_URL + "/projects?" + params.toString());
            if (!response.ok) {
                throw Error(response.statusText);
            }
            const projectObj = await response.json();

            this.setState({
                isFetching: false,
                project : projectObj,
                allTopics: allTopics
            });
        } catch (error) {
            this.setState({
                isFetching: false,
                project : null,
                allTopics: []
            });
        }
    }



    render() {


        if(!this.state.isFetching && this.state.project == null){
            return <div>project not found</div>
        }
        return (
            this.state.isFetching? (
                <LoadingScreen>Arbeitsblatt wird geladen</LoadingScreen>
            ):(
                <>
                    {this.props.editMode ? <div>
                        <ProjectEditor
                            project={this.state.project}
                            allTopics={this.state.allTopics}
                        />
                        </div>:
                        <>
                            {/* FOR DEBBUG REASONS... UserController.isInstructorAdmin() &&
                                <WorksheetViewStateSwitcher
                                    instructorMode={this.state.instructorMode}
                                    editMode={this.state.editMode}
                                    readOnlyMode={this.state.readOnlyMode}
                                    onStatesChange={(instructorMode,editMode,readOnlyMode)=> {
                                        this.setState({
                                            instructorMode: instructorMode,
                                            editMode: editMode,
                                            readOnlyMode: readOnlyMode
                                        })
                                    }}
                                />
                            */}


                            <WorksheetViewComponent
                                key={this.state.readOnlyMode+"_"+this.state.instructorMode+"_"+this.state.editMode}
                                readOnlyMode={this.state.readOnlyMode}
                                instructorMode={this.state.instructorMode}
                                showEditorView={this.state.editMode}
                                worksheet={null}
                                topicId={this.state.project.projectId}
                                sections={this.state.project.sections}
                                trainee={null}
                            ></WorksheetViewComponent>
                    </>

                    }
                </>
            )
        );
    }
}


export default ProjectPage;