import React, {Component} from "react";
import LoadingScreen from "../../includes/LoadingScreen";
import {
    faArrowRight, faColumns,
    faFilePdf, faMaximize, faMinimize,
    faSave, faUser
} from "@fortawesome/free-solid-svg-icons";
import UserController from "../../controller/UserController";
import {Button, Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import APIController from "../../controller/APIController";
import PagePopup from "../../components/PagePopup";
import EvaluationFormInput from "../../components/WorksheetEditorComponents/EvaluationFormInput";
import WorksheetViewComponent from "../../components/WorksheetViewComponent";
import WorksheetUtil from "../../util/WorksheetUtil";
import './WorksheetEditorPage.css';
import ProjectTaskDescription from "../../components/ProjectTaskDescription";
import PhaseStateInfoBox from "../../components/PhaseStateInfoBox";
import StatusBadge from "../../components/badges/StatusBadge";
import he from "he";
import ToastController from "../../controller/ToastController";



class WorksheetEditorPage extends Component {
    constructor(props) {
        super(props);

        this.onComponentUpdate = this.onComponentUpdate.bind(this);
        this.handleSaveWorkingSheet = this.handleSaveWorkingSheet.bind(this);
        this.onCommentsUpdated = this.onCommentsUpdated.bind(this);
        this.handleFinishWorkingSheet = this.handleFinishWorkingSheet.bind(this);
        this.handleReopenWorkingSheet = this.handleReopenWorkingSheet.bind(this);
        this.handleEvaluateWorkingSheet = this.handleEvaluateWorkingSheet.bind(this);
        this.handleExportPDFClicked = this.handleExportPDFClicked.bind(this);
        this.onWorksheetUpdated = this.onWorksheetUpdated.bind(this);

        this.state = {
            worksheetId: props.worksheetId,
            traineeId: props.traineeId,
            trainee: null,
            instructorMode: props.instructorMode, // check access control
            isFetching: true,
            worksheet : null,
            access_rights: null,
            viewMode: 1, //0=minimized,a=normal,2=maximized
            popupProps: {
                title: "",
                body: "",
                show: false,
                showFooter: false
            }
        };


        this.toastControllerRef = React.createRef(); // Ref für den ToastController


    }

    onComponentUpdate(component){
        var sectionIndex = null;
        var componentIndex = null;
        this.state.worksheet.sections.map((section, secIndex) => {
            section.components.map( (comp, compIndex) => {
                if(comp.id == component.id){
                    sectionIndex = secIndex;
                    componentIndex = compIndex;
                }
            });
        });
        const worksheet = this.state.worksheet;
        worksheet.sections[sectionIndex].components[componentIndex] = component;
        this.setState({
            worksheet:worksheet
        });
    }


    async componentDidMount(){
        try {
            let user = UserController.getUser();
            if(this.state.traineeId !== user.id){
                //throw Error("No Access possible");
            }
            const worksheetId = encodeURIComponent(this.state.worksheetId);
            const worksheet = await APIController.getWorksheetById(worksheetId);
            const trainee = await APIController.getTraineeById(worksheet.traineeId);

            if(worksheet.traineeId === user.id){
                this.setState({
                    isFetching: false,
                    worksheet : worksheet,
                    trainee : trainee,
                    access_rights : worksheet.access_rights
                });
            }else{
                this.setState({
                    isFetching: false,
                    worksheet : worksheet,
                    trainee : trainee,
                    access_rights : worksheet.access_rights
                });
            }
        } catch (e) {
            console.log(e)
            this.setState({
                isFetching : false,
                worksheet: null,
                access_rights : null
            });
        }

        let lowestKnownOffset = -1;
        window.addEventListener("resize", () => {
            lowestKnownOffset = -1;
            let $stickyObject = document.getElementById("stickyObject");
            let $marker = document.getElementById("stickyMarker");
            let $stickyImageColumn = document.getElementById("stickyImageColumn");
            if($stickyObject == null || $marker == null || $stickyImageColumn == null)
                return;
            $stickyObject.style.width = $stickyImageColumn.clientWidth+"px";
        });

        window.addEventListener("scroll", (event) => {
            let $stickyObject = document.getElementById("stickyObject");
            let $marker = document.getElementById("stickyMarker");
            let $stickyImageColumn = document.getElementById("stickyImageColumn");
            if($stickyObject == null || $marker == null || $stickyImageColumn == null)
                return;
            let requestedFrame;
            if (requestedFrame) { return; }
            requestedFrame = requestAnimationFrame(() => {
                // if it's sticky to top, the offset will bottom out at its natural page offset
                if (lowestKnownOffset === -1) { lowestKnownOffset = $marker.offsetTop; }
                lowestKnownOffset = Math.min(lowestKnownOffset, $marker.offsetTop);
                // this condition assumes that $Title is the only sticky element and it sticks at top: 0px
                // if there are multiple elements, this can be updated to choose whichever one it furthest down on the page as the sticky one
                if (window.scrollY >= lowestKnownOffset) {
                    $stickyObject.classList.add("--stuck");
                    $stickyObject.style.width = $stickyImageColumn.clientWidth+"px";
                } else {
                    $stickyObject.classList.remove("--stuck");
                }
                requestedFrame = undefined;
            });
        })
    }


    handleReopenWorkingSheet() {
        var self = this;
        return APIController.reopenWorkingSheet(
            this.state.worksheet,
            (json) => {
                console.log("worksheet updated...");
                console.log(json);
                // eslint-disable-next-line no-restricted-globals
                history.back();
            },
            (err) => {
                console.log(`Error: ${err}`)
            });
    }

    async handleExportPDFClicked() {
        window.location = "/worksheets/"+this.state.worksheet.id+"/pdf";
    }

    async handleEvaluateWorkingSheet() {
        var self = this;




        self.setState({
                popupProps: {
                    title: <h2><FontAwesomeIcon icon={faUser} /> {he.decode(self.state.trainee.name)}, {he.decode(self.state.trainee.firstname)}</h2>,
                    body: <EvaluationFormInput
                        worksheet={self.state.worksheet}
                        formMode={true}
                        mode={"LargeView"}
                        onFinishEvaluation={async (updatedEvaluation) => {
                            let evaluations = {};
                            let evaluation = JSON.parse(JSON.stringify(EvaluationFormInput.getFormattedEvaluation(updatedEvaluation)));
                            evaluation = EvaluationFormInput.recalculatePraktischeFetigkeitenSumResult(evaluation);
                            evaluations[self.state.worksheet.traineeId] = evaluation;

                            self.setState(prevState => ({
                                ...prevState,
                                isFetching: true
                            }));
                            await APIController.updateEvaluationsByCourseAndTopic(self.state.worksheet.courseId, self.state.worksheet.topic, evaluations);

                            // eslint-disable-next-line no-restricted-globals
                            history.back();
                        }}/>,
                    show: true,
                    showFooter: false
                }
            }
        );

    }

    handleSaveWorkingSheet() {
        var self = this;
        return APIController.updateWorksheet(
            this.state.worksheet,
            (json) => {
                console.log("worksheet updated...");
                console.log(json);
                // eslint-disable-next-line no-restricted-globals
                window.alert("Arbeitsblatt wurde erfolgreich gespeichert")
                window.location.reload();
            },
            (err) => {
                window.alert("Leider ist ein Fehler aufgetreten. Das Arbeitsblatt konnte nicht gespeichert werden.")
                console.log(`Error: ${err}`)
            });
    }

    async onCommentsUpdated(commentText) {
        try{
            await APIController.updateWorksheetComments(this.state.worksheet);
            this.toastControllerRef.current.sendToastMessage("Änderungen gespeichert");
        }catch (e) {
            this.toastControllerRef.current.sendToastMessage("Fehler beim Speichern",true);
        }
    }
    onWorksheetUpdated(updatedWorksheet){
        var self = this;
        return APIController.updateWorksheet(
            self.state.worksheet,
            (json) => {
                console.log("worksheet updated...");
                console.log(json);
                self.setState(
                    {
                        worksheet: updatedWorksheet
                    }
                )
            },
            (err) => {
                console.log(`Error: ${err}`)
            });

    }



    getPopUpPropsForAlmostFinished(){
        return {
            title: <h2>Arbeitsblatt zur Kontrolle einreichen</h2>,
            body: <><h5>Alle Aufgaben sicher abgeschlossen?</h5>
                <span>{"Wenn du deine Antworten zur Kontrolle einreichst, geht's in die nächste Phase"}</span>
                <p><br /><b>Du befindest dich hier</b></p>
                <PhaseStateInfoBox worksheet={this.state.worksheet}></PhaseStateInfoBox>
            </>,
            show: true,
            showFooter: true,
            saveButtonText: "Ja, einreichen und in die nächste Phase",
            closeButtonText: "Nein, noch nicht einreichen"
        }
    }

    getPopUpPropsForLastStep(){
        return {
            title: <h2>Fast geschafft!</h2>,
            body: <>
                <h5>Alle Aufgaben sicher abgeschlossen?</h5>
                <span>{"Du befindest dich in der letzten Phase. Wenn du das Arbeitsblatt einreichst, wird es final kontrolliert."}</span>
            </>,
            show: true,
            showFooter: true,
            saveButtonText: "Ja, Arbeitsblatt abschließen",
            closeButtonText: "Nein, noch nicht abschließen"
        }
    }




    async handleFinishWorkingSheet() {
        let self = this;
        let lastPhase = PhaseStateInfoBox._isInLastPhase(self.state.worksheet);
        this.setState({
                popupProps: {
                    ...lastPhase?
                        this.getPopUpPropsForLastStep():this.getPopUpPropsForAlmostFinished(),
                    onClose:  ()=>
                        this.setState(prevState => ({
                            popupProps: {
                                ...prevState.popupProps,
                                show: false
                            }
                        })),
                    onSave: async () => {
                        try {
                            let result = await APIController.updateWorksheetAndPhase(self.state.worksheet);
                            window.alert("Arbeitsblatt erfolgreich zur Kontrolle eingereicht");
                            window.location.reload();
                        } catch (e) {
                            window.alert(JSON.stringify(e))
                            window.alert("Leider konnte das Arbeitsblatt aufgrund eines technischen Fehlers nicht eingereicht werden");
                        }
                    }
                }
            }
        );
    }


    getTitleImage(style = {background:'white'}, classNames= ""){
        let filesArray = this.state.worksheet.files?this.state.worksheet.files:[];
        let url =  WorksheetUtil.findPrimaryTitleImageUrl(filesArray);
        if(url == null){
            return null
        }else{
            return <img className={classNames} style={style} src={url}  alt={""}/>
        }
    }


    _renderButtonPanel(){
        let lastPhase = PhaseStateInfoBox._isInLastPhase(this.state.worksheet);

        return <div className={"worksheet-button-panel"}>
            <Row>
                <Col xs={12} sm={12} lg={12}>
                    <div>
                        <div style={{padding:"1em",marginBottom:'1em'}} align={"center"}>
                            {(!this.props.instructorMode && this.state.worksheet.state === StatusBadge.TYPES.OPEN_WORKING_SHEET.state) &&
                                <>
                                    <Button style={{marginRight:"1em",marginBottom:"1em"}} onClick={this.handleSaveWorkingSheet} variant="success"><FontAwesomeIcon icon={faSave} /> Speichern</Button>
                                    <Button style={{marginRight:"1em",marginBottom:"1em"}} onClick={this.handleFinishWorkingSheet} variant="light">{lastPhase?"➡️ Arbeitsblatt abschließen":"➡️ Einreichen"}</Button>
                                </>
                            }
                            {this.props.instructorMode &&
                                <>
                                    <Button style={{marginRight:"1em"}} onClick={this.handleEvaluateWorkingSheet}
                                            variant="success" className={"btn btn-lg"}><FontAwesomeIcon icon={faArrowRight} /> Bewertung abgeben
                                    </Button>
                                </>
                            }
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    }



    _renderImageButtons(renderPosiitonAbsoluteFixed = true){
        return(
            <div className={renderPosiitonAbsoluteFixed?"imageNavigation sticky":"imageNavigation"}>
                {this.state.viewMode === 0 &&
                    <div>
                        {this.getTitleImage({width:'8em'})}
                    </div>
                }
                <div>
                    <Button disabled={this.state.viewMode === 0} onClick={()=>{
                        this.state.viewMode = 0;
                        this.setState(this.state);
                    }}><FontAwesomeIcon icon={faMinimize} /></Button>
                    <Button disabled={this.state.viewMode === 1} onClick={()=>{
                        this.state.viewMode = 1;
                        this.setState(this.state);
                    }}><FontAwesomeIcon icon={faColumns} /></Button>
                    <Button disabled={this.state.viewMode === 2} onClick={()=>{
                        this.state.viewMode = 2;
                        this.setState(this.state);
                    }}><FontAwesomeIcon icon={faMaximize} /></Button>
                </div>
            </div>
        )
    }

    _renderMain(){
        let filesArray = this.state.worksheet.files?this.state.worksheet.files:[];

        if(filesArray.length > 0 && this.state.viewMode === 2){
            let url =  WorksheetUtil.findPrimaryTitleImageUrl(filesArray);
            return  <>
                <div className={"image-maximized-container"}>
                    <div style={{width:'100%',height:'100%',backgroundImage:"url('"+url+"')"}} className={"image-container"}></div>
                </div>
            </>
        }else if(filesArray.length > 0 && this.state.viewMode === 1){
            return <Row>
                <Col xs={12}  md={6} lg={4} style={{display:'flex',justifyContent:'right',alignItems:'start'}}>
                    <div id={"stickyImageColumn"} style={{width:'100%'}}>
                        <div id={"stickyMarker"}></div>
                        <div id={"stickyObject"} className={"stickytitleimage worksheet-title-image viewmode-1"}>
                            <div>
                                {this.getTitleImage()}
                            </div>
                            <div>
                                <>{this._renderImageButtons(false)}</>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={12} md={6} lg={8} style={{display:'flex',justifyContent:'right',alignItems:'start'}}>
                    <div className={"worksheet-scroll-panel"}>
                        <div className={"worksheet-scroll-panel-inner"}>
                            <WorksheetViewComponent
                                onComponentUpdate={this.onComponentUpdate}
                                readOnlyMode={false}
                                instructorMode={this.state.instructorMode}
                                showEditorView={false}
                                onCommentsUpdated={this.onCommentsUpdated}
                                topicId={this.state.worksheet.topic}
                                sections={this.state.worksheet.sections}
                                worksheet={this.state.worksheet}
                                trainee={this.state.trainee}
                                onWorksheetUpdated={this.onWorksheetUpdated}
                            ></WorksheetViewComponent>
                        </div>
                    </div>
                </Col>
            </Row>
        }else{
            return <>
                <WorksheetViewComponent
                    onComponentUpdate={this.onComponentUpdate}
                    readOnlyMode={false}
                    instructorMode={this.state.instructorMode}
                    showEditorView={false}
                    onCommentsUpdated={this.onCommentsUpdated}
                    topicId={this.state.worksheet.topic}
                    sections={this.state.worksheet.sections}
                    worksheet={this.state.worksheet}
                    trainee={this.state.trainee}
                    onWorksheetUpdated={this.onWorksheetUpdated}
                ></WorksheetViewComponent>
            </>
        }
    }

    render() {
        if(!this.state.isFetching && this.state.worksheet == null && this.state.trainee == null){
            return <div>Worksheet Not found</div>
        }else{
            return (
                this.state.isFetching ? (<LoadingScreen><h2>Arbeitsblatt wird geladen</h2></LoadingScreen>):(
                    <div className={"WorksheetEditorPage"}>
                        {this.state.viewMode !== 2 &&
                            <>
                                <PagePopup
                                    className={"evaluationPopup"}
                                    onClose={()=>this.setState({popupProps:{show:false}})}
                                    key={this.state.popupProps.show}
                                    {...this.state.popupProps}>
                                </PagePopup>
                                <div className={"mb-3"}>
                                    <ProjectTaskDescription
                                        topicId={this.topicId}
                                        worksheet={this.state.worksheet}
                                        trainee={this.state.trainee}
                                        showActionButtons={true}
                                        onWorksheetUpdated={this.onWorksheetUpdated}
                                    />
                                </div>
                            </>
                        }
                        <div style={{maxWidth:'1900px',margin:'0px auto'}}>
                            {this._renderMain()}
                        </div>
                        {this._renderButtonPanel()}
                        {this.state.viewMode !== 1 &&
                            <>ss{this._renderImageButtons(true)}</>
                        }
                        <ToastController ref={this.toastControllerRef} />
                    </div>
                )
            );
        }
    }
}

export default WorksheetEditorPage;