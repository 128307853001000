import _ from "lodash";
import * as yup from "yup";
import { sha256 } from 'js-sha256';

class ObjectUtil {


    static hashid(object){
        const currentDate = new Date();
        const milliseconds = currentDate.getMilliseconds();
        let obj = {
            time: milliseconds,
            value: object
        };
        return sha256(JSON.stringify(obj));
    }

    static async isTraineeProfileFinished(obj){
        const profileMatcher = yup.object().shape({
            communicationData: yup.object().shape({
                phone: yup.string(),
                email: yup.string().required().strict(true),
                mobile: yup.string().required().strict(true),
            }),
            bankDetails: yup.object().shape({
                iban: yup.string().required().strict(true),
                creditInstitution: yup.string().required().strict(true),
                bic: yup.string().required().strict(true),
                differentAccountHolder: yup.string(),
                lfdnr: yup.number(),
            }),
            legalGuardian: yup.object().shape({
                salutation: yup.string().required().strict(true),
                name: yup.string().required().strict(true),
                firstname: yup.string().required().strict(true),
                street: yup.string().required().strict(true),
                zip: yup.string().required().strict(true),
                city: yup.string().required().strict(true),
                phone: yup.string().required().strict(true),
                email: yup.string()
            }),
        });
        return await profileMatcher.isValid(obj);
    }

    static checkEmpty(obj){
        for(let key in obj){
            //if the value is 'object'
            if(obj[key] instanceof Object){
                if(ObjectUtil.checkEmpty(obj[key]) === false) return false;
            }
            //if value is string/number
            else{
                //if array or string have length is not 0.

                if(obj[key] === "" || _.isNil(obj[key])) return false;
            }
        }
        return true;
    }

    static convertNullToEmpty(obj) {
        // Base case: If the input is not an object, return it
        if (typeof obj !== 'object' || obj === null) {
            // Check if the value is null, undefined, or "null", and convert it to an empty string
            if (obj === null || obj === undefined || obj === "null") {
                return "";
            }
            // Otherwise, return the original value
            return obj;
        }

        // If the input is an array, recursively convert each element
        if (Array.isArray(obj)) {
            return obj.map(item => this.convertNullToEmpty(item));
        }

        // If the input is an object, recursively convert each property
        const newObj = {};
        for (const key in obj) {
            // Skip prototype properties
            if (!obj.hasOwnProperty(key)) {
                continue;
            }
            newObj[key] = this.convertNullToEmpty(obj[key]);
        }
        return newObj;
    }
}


export default ObjectUtil;