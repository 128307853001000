import React, {Component} from 'react';


import config from "../../config";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './MyCoursesListPage.css'
import {
    faSearchMinus
} from "@fortawesome/free-solid-svg-icons";
import LoadingScreen from "../../includes/LoadingScreen";
import PageContent from "../../includes/PageContent";
import CenteredMessage from "../../includes/CenteredMessage";
import {addSessionTokenToRequestHeader} from "../../services/fetchInterceptor.service";
import CoursesListAccordions from "../../components/CoursesListAccordions";
import KWFilter from "../../includes/KWFilter";
import DateTimeUtil from "../../util/DateTimeUtil";



class MyCoursesListPage extends Component {

    constructor(props) {
        super(props);
        this.location = props.location;
        this.state = {
            isFetching: true,
            reload:true,
            page:1,
            pagesTotel:0,
            numTotal:0,
            courses : [],
            worksheets:[],
            userType:props.userType !== undefined ? props.userType:null,
            userId:props.params !== undefined ? props.params.id:null,
            typeFilter: 'alle',
            currentKw: DateTimeUtil.getCurrentKW(),
        };
    }

    async sync(courseId){
        this.setState({
            isFetching:true
        });
        const params = new URLSearchParams({
            courseId: courseId
        });
        addSessionTokenToRequestHeader();
        let resp = await fetch(config.API_HOST_URL + "/courses/sync?" + params.toString());
        if (!resp.ok) {
            throw Error(resp.statusText);
        }else{
        this.setState({
            isFetching:false
        });
        window.location.reload();

        }

    }


    async reload(){

        if(window.location.hash !== ""){
            let yearFromHash = window.location.hash.replace( '#', "").split("-")[0];
            let weekNumberFromHash = window.location.hash.replace( '#', "").split("-")[1];
            let kw = DateTimeUtil.getKW(weekNumberFromHash,yearFromHash);
            this.state.currentKw = kw;
        }

        try {
            this.setState({
                isFetching:true
            })
            //window.alert(JSON.stringify(this.state.currentKw))

            let queryParams = {
                page: this.state.page,
                typeFilter: 'noInstructors'
            };
            if(this.state.typeFilter != null){
                queryParams["typeFilter"] = this.state.typeFilter;
            }
            if(this.state.userType != null){
                queryParams["userType"] = this.state.userType;
            }
            if(this.state.userId != null){
                queryParams["userId"] = this.state.userId;
            }
            if(this.state.currentKw != null){
                queryParams["from"] = this.state.currentKw.mondayDate;
                queryParams["to"] = this.state.currentKw.sundayISO;
            }

            let params = new URLSearchParams(queryParams);
            addSessionTokenToRequestHeader();
            let response = await fetch(config.API_HOST_URL + "/courses?"+params.toString());
            if (!response.ok) {
                throw Error(response.statusText);
            }
            let json = await response.json();

            json.data = json.data.reverse();

            for (let i=0;i< json.data.length; i++) {
                json.data[i].worksheetsArray = [];
                Object.keys(json.data[i].worksheets).forEach(function(traineeId,index) {
                    let worksheetArray = json.data[i].worksheets[traineeId];
                    json.data[i].worksheetsArray = json.data[i].worksheetsArray.concat(worksheetArray);
                });
            }

            this.setState({
                isFetching: false,
                reload:false,
                courses : json.data,
                pagesTotel :json.pages_total,
                numTotal: json.num_total
            });
        } catch (error) {
            this.setState({
                isFetching: false,
                courses : [],
                worksheets: []
            });
        }
    }


    async componentDidMount() {


        await this.reload();

    }


    render() {



        return (
            <div className={"MyCoursesListPage"}>
                {this.state.isFetching? (
                    <PageContent>
                        <>
                            <KWFilter
                                weekNumber={this.state.currentKw.weekNumber}
                                year={this.state.currentKw.year}
                                dateTimespan={this.state.currentKw.dateTimespan}
                                onChange={(weekday,year) => {
                                    let kw = DateTimeUtil.getKW(weekday,year);
                                    this.state.currentKw = kw;
                                    this.reload();
                                }}
                                style={{marginBottom:'0em'}}
                            />
                            <LoadingScreen><h2>Kurse werden geladen</h2></LoadingScreen>
                        </>

                    </PageContent>
                ):(
                    <PageContent>
                        <KWFilter
                            key={this.state.currentKw.weekNumber}
                            weekNumber={this.state.currentKw.weekNumber}
                            year={this.state.currentKw.year}
                            location={this.location}
                            dateTimespan={this.state.currentKw.dateTimespan}
                            onChange={(weekday,year) => {
                                let kw = DateTimeUtil.getKW(weekday,year);
                                this.state.currentKw = kw;
                                window.location.hash = '#'+kw.year+"-"+kw.weekNumber;
                                this.reload();
                            }}
                            style={{marginBottom:'0em'}}
                        />
                            {this.state.courses.length == 0?
                                <CenteredMessage>
                                    <FontAwesomeIcon icon={faSearchMinus} />
                                    Keine Kurse
                                </CenteredMessage>
                                :<>

                                    <CoursesListAccordions onSyncButtonClicked={(courseId) => {
                                        this.sync(courseId);
                                    }} courses={this.state.courses} />

                                </>
                            }
                    </PageContent>
                )}
            </div>
        );
    }
}


export default MyCoursesListPage;