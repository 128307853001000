import React, {Component} from 'react';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './SingleCoursePage.css'
import {
    faArrowLeft,
      faClose,
    faDiagramProject,   faPaperPlane,
    faSheetPlastic, faSync,  faUser, faWarning,
} from "@fortawesome/free-solid-svg-icons";
import LoadingScreen from "../../includes/LoadingScreen";
import PageContent from "../../includes/PageContent";
import UserController from "../../controller/UserController";
import APIController from "../../controller/APIController";
import WorksheetTableAccordion from "../../components/WorksheetTableAccordion";
import he from "he";
import {Alert, Badge, Button, Col, Container, Row} from "react-bootstrap";
import WorksheetListStateBadges from "../../components/badges/WorksheetListStateBadges";
import PradleAccordion from "../../components/PradleAccordion";
import PagePopup from "../../components/PagePopup";
import SingleSelectInputFormField from "../../components/formFields/SingleSelectInputFormField";
import {addSessionTokenToRequestHeader} from "../../services/fetchInterceptor.service";
import config from "../../config";
import WorksheetUtil from "../../util/WorksheetUtil";
import defaultImageUrl from "../../assets/topic_images/1.jpg";
import TopicKachel from "../../components/TopicKachel";


class SingleCoursePage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isFetching: true,
            user: UserController.getUser(),
            instructorId: props.instructorId,
            courseId:props.params.id,
            topicId:props.params.topicId !== undefined?props.params.topicId:null,
            openSections:{
                'workingsheets':false
            },
            popupProps: {
                title: "",
                body: "",
                show: false,
                showFooter: false
            },
            worksheetHandoutSelectedTraineeId: null,
            worksheetHandoutSelectedTopicId: null,
        }
        this.params = props.params;
        this.onWorksheetsUpdated = this.onWorksheetsUpdated.bind(this);
        this.renderPopupBody = this.renderPopupBody.bind(this);
    }

    async componentDidMount() {
        try {
            let course = await APIController.getCourseById(this.state.courseId);
            this.setState({
                isFetching: false,
                course: course,
            })
        } catch (e) {
            console.log(e);
            this.setState({
                isFetching: false,
                course: null,
            })
        }
    }

    onWorksheetsUpdated(updatedWorksheets){
        let allworksheets = [];
        Object.keys(updatedWorksheets).map((course, sectionIndex) => {
            updatedWorksheets[course].map((w) => {
                allworksheets.push(w);
            });
        });
        this.setState({
            worksheetsForProgress: allworksheets
        });
    }

    getTopicName(topicId){
        const item = this.state.course.topics.find(obj => obj._id === topicId);
        if (item) {
            return item.name;
        } else {
            return "";
        }
    }

    async handoutWorkingsheet(traineeId,projectId){
        const response = await APIController.assignWorkingsheet(this.state.course._id, traineeId, projectId);
        if(response){
            await this.setState({popupProps:{show:false}});
            window.location.reload();
        }else{
            await this.setState({popupProps:{show:false}});
            alert("Es konnte kein Arbeitsblatt erstellt werden");
        }
    }

    renderPopupBody(){
        let topics = this.state.course.topics.map((topic,idx)=>{
            return(
                {name: he.decode("("+topic._id+") "+topic.name), value: topic._id}
            )
        });
        let trainees = this.state.course.trainees.map((trainee,idx)=>{
            return(
                {name: he.decode(trainee.name+", "+trainee.firstname), value: trainee._id}
            )
        });

        this.state.worksheetHandoutSelectedTraineeId = trainees[0].value;
        this.state.worksheetHandoutSelectedTopicId = topics[0].value;

        return (
            <>
                <SingleSelectInputFormField
                    title={<><FontAwesomeIcon icon={faUser} /> Azubi auswählen</>}
                    selectedValue={this.state.worksheetHandoutSelectedTraineeId}
                    options={trainees}
                    onChange={(selectedOption) => {
                        this.setState({
                            worksheetHandoutSelectedTraineeId: selectedOption.value,
                        })
                    }}
                />
                <SingleSelectInputFormField
                    title={<><FontAwesomeIcon icon={faDiagramProject} /> Projekt auswählen</>}
                    selectedValue={this.state.worksheetHandoutSelectedTopicId}
                    options={topics}
                    onChange={(selectedOption) => {
                        this.setState({
                            worksheetHandoutSelectedTopicId: selectedOption.value,
                        })
                    }}
                />

            </>
        )
    }

    _getTitleImageUrl(worksheet){
        if(worksheet === undefined){
            return defaultImageUrl;
        }
        let filesArray = [];
        if(worksheet.hasOwnProperty('files')){
            filesArray = worksheet.files;
        }
        let url =  WorksheetUtil.findPrimaryTitleImageUrl(filesArray);
        if(url == null){
            url = defaultImageUrl;
        }
        return url;
    }

    render() {
        var self = this;

        if(this.state.isFetching){
            return <LoadingScreen/>;
        }

        if(this.state.topicId != null) {
            //let worksheetsOfTrainee = this.state.worksheetsGroupedByTopicId[this.state.topicId];

           // let worksheets = _.groupBy(worksheetsOfTrainee, "topic");
            let worksheetsFilteredByTopic = this.state.course.worksheets.filter(ws => ws.topic === this.state.topicId);
            return (

                <>
                    <div className={'SingleCoursePage'}>
                        <PageContent>
                            <Container>
                    <div>
                        <div style={{marginBottom:'1em'}}>
                            <Row>
                                <Col xs={12} style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                    <h1 style={{textAlign:'center'}}><a href={"/courses/"+this.state.course._id}><FontAwesomeIcon icon={faArrowLeft} /></a> {this.state.course.name}</h1>
                                </Col>
                                <Col xs={12} sm={10} style={{display:'flex',justifyContent:'left',alignItems:'center'}}>
                                    <h3 style={{marginRight:'0.5em', minWidth:'5em'}}><FontAwesomeIcon icon={faDiagramProject} /> {this.state.topicId}</h3>
                                    <h2><span style={{display:'inline-block'}}>{he.decode(this.getTopicName(this.state.topicId))}</span>
                                    </h2>
                                </Col>
                                <Col  xs={6} sm={2} className={"d-none d-sm-flex"} style={{display:'flex',justifyContent:'right',alignItems:'right'}}>
                                    <img style={{background:'white', maxHeight:'3em', borderRadius:'100%'}} src={this._getTitleImageUrl(worksheetsFilteredByTopic[0])} />
                                </Col>
                            </Row>
                        </div>
                        <WorksheetTableAccordion
                            groupedByTopics={true}
                            params={self.params}
                            courseId={self.state.courseId}
                            topicId={self.state.topicId}
                            allTopics={self.state.course.topics}
                            userType={UserController.getUser().type}
                            onWorksheetsUpdated={self.onWorksheetsUpdated}
                            worksheets={this.state.course.worksheets}
                        />
                    </div>
                            </Container>
                        </PageContent>
                    </div>
                </>

            )
        }




        return (
            <>
                <div className={'SingleCoursePage'}>
                    <PageContent>
                        <Container>

                            {this.state.course.topics.length == 0?
                                <Alert variant={"warning"}>
                                    <h3>
                                        <small>Keine <FontAwesomeIcon icon={faDiagramProject} /> Projekte zugeordnet.</small>
                                    </h3>
                                    <p>
                                        Sie müssen im Uniplus-System dem Kurs erst ein Projekt zuordnen
                                    </p>
                                </Alert>
                                :<>

                                    <div style={{marginBottom:'1em'}}>

                                        <Row>
                                            <Col xs={12} lg={7} style={{display:'flex',justifyContent:'left',alignItems:'center'}}>
                                                <h1 style={{textAlign:'center'}}>{this.state.course.name}</h1>
                                                <Badge style={{marginLeft:'1em'}} bg={"dark"}><FontAwesomeIcon icon={faDiagramProject} /> Projekte</Badge>

                                            </Col>
                                            {UserController.isInstructor() &&
                                            <Col xs={12} lg={5} style={{display:'flex',justifyContent:'right',alignItems:'center'}}>
                                                <Button type="button" className={"btn btn-primary btn-xs"} style={{marginRight:'0.5em',display:'inline-block'}} variant="info" onClick={async ()=>{

                                                    this.setState({
                                                        isFetching:true
                                                    });
                                                    const params = new URLSearchParams({
                                                        courseId: this.state.course._id
                                                    });
                                                    addSessionTokenToRequestHeader();
                                                    let resp = await fetch(config.API_HOST_URL + "/courses/sync?" + params.toString());
                                                    if (!resp.ok) {
                                                        throw Error(resp.statusText);
                                                    }else{
                                                        this.setState({
                                                            isFetching:false
                                                        });
                                                        window.location.reload();
                                                    }
                                                }}>
                                                    <FontAwesomeIcon icon={faSync}></FontAwesomeIcon> Sync</Button>
                                                <Button type="button" className={"btn btn-primary btn-xs"} style={{marginRight:'0.5em',display:'inline-block'}} variant="primary" onClick={()=>{
                                                    this.setState({popupProps:{
                                                            title: <><FontAwesomeIcon icon={faSheetPlastic} /> Digitales Arbeitsblatt - Manuelle Freigabe</>,
                                                            body: this.renderPopupBody(),
                                                            show: true,
                                                            showFooter: true
                                                        }});
                                                }}>
                                                    <FontAwesomeIcon icon={faSheetPlastic}></FontAwesomeIcon> Freigabe</Button>
                                                {
                                                /*
                                                <Button type="button" className={"btn btn-primary btn-xs"} variant="danger" onClick={async ()=>{
                                                    if (window.confirm("Wirklich alle Arbeitsblätter in diesem Kurs zu diesem Projekt löschen?")) {

                                                        //await APIController.migrateAllWorksheets();

                                                        await APIController.deleteAllWorksheetsByCourseId(this.state.course._id).then(r => {
                                                            window.alert("Arbeitsblätter erfolgreich gelöscht");
                                                            window.location.reload();
                                                        })
                                                    }
                                                }}>
                                                    <FontAwesomeIcon icon={faWarning}></FontAwesomeIcon> Löschen</Button>
                                                */
                                                }
                                            </Col>
                                            }
                                        </Row>

                                    </div>

                                    <PagePopup
                                        className={"evaluationPopup"}
                                        onClose={()=>this.setState({popupProps:{show:false}})}
                                        onSave={async ()=>{
                                            await this.handoutWorkingsheet(
                                                this.state.worksheetHandoutSelectedTraineeId,
                                                this.state.worksheetHandoutSelectedTopicId,
                                            );
                                        }}
                                        saveButtonText={<><FontAwesomeIcon icon={faPaperPlane} /> Arbeitsblatt absenden</>}
                                        closeButtonText={<><FontAwesomeIcon icon={faClose} /> Abbrechen</>}
                                        key={this.state.popupProps.show}
                                        {...this.state.popupProps}>
                                    </PagePopup>

                                        <Row>
                                            {this.state.course.topics.map((topic,idx)=>{
                                                return <Col xs={12} sm={6} lg={4} xl={3}>
                                                    <TopicKachel
                                                        topicId={topic._id}
                                                        topicName={topic.name}
                                                        courseId={this.state.course._id}
                                                        worksheets={this.state.course.worksheets}
                                                    ></TopicKachel>
                                                </Col>
                                            })}
                                        </Row>

                                </>
                            }
                        </Container>
                    </PageContent>
                </div>
            </>)


    }
}


export default SingleCoursePage;