import React, {Component} from 'react';
import './PhaseStateInfoBox.css';
import StatusBadge from "./badges/StatusBadge";
import {faFileText} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


class PhaseStateInfoBox extends Component {

    constructor(props) {
        super(props);
        this.worksheet = props.worksheet;
    }



    static getRedablePermissionByWorksheetAndSectionId(worksheet, sectionId){
        return PhaseStateInfoBox.getHumanStringByPhaseValue(
            PhaseStateInfoBox.getPermissionByWorksheetAndSectionId(worksheet, sectionId)
        );
    }


    static isVisibleSectionByPermission(worksheet, sectionId){
        let permission = PhaseStateInfoBox.getPermissionByWorksheetAndSectionId(worksheet, sectionId);
        return permission !== "H";
    }

    static isWritableSectionByPermission(worksheet, sectionId){
        let permission = PhaseStateInfoBox.getPermissionByWorksheetAndSectionId(worksheet, sectionId);
        return permission === "W";
    }

    static getPermissionByWorksheetAndSectionId(worksheet, sectionId){
        let foundPhaseSetting = null;
        worksheet.sections.filter(sec => sec.id === sectionId).forEach(section => {

            if(section.phaseSettings === undefined || !Array.isArray(section.phaseSettings)){
                //workaround for older worksheet formats
                if(section.phaseSettings !== undefined && section.phaseSettings.hasOwnProperty(worksheet.phase)){
                    foundPhaseSetting = section.phaseSettings[worksheet.phase];
                }else{
                    section.phaseSettings = [];
                }
            }else{
                const selectedPhases = section.phaseSettings.filter(obj => obj.id === worksheet.phase);
                if(selectedPhases.length > 0){
                    //selectedPhase found
                    foundPhaseSetting = selectedPhases[0].value;
                }
            }



        });
        if(foundPhaseSetting != null){
            return foundPhaseSetting;
        }else{
            return "W" //DEFAULT VALUE
        }
    }


    static getHumanStringByPhaseValue(phaseValue){
        // eslint-disable-next-line default-case
        switch (phaseValue){
            case "N":
                return "⚠️ Undefiniert";
            case "H":
                return "🚫 Ausgeblendet";
            case "R":
                return "🔒 Lesezugriff";
            case "W":
                return "✏️ Schreibzugriff";
            default:
                return "?"
        }
    }

    static _isInLastPhase(worksheet){
        let index = worksheet.phases.findIndex(obj => obj.id === worksheet.phase);
        return index === worksheet.phases.length-1;
    }

    static renderWorksheetStateBubble(worksheet, short=false){
        let stateObject = StatusBadge.getStatus(worksheet.state);
        return <span style={{backgroundColor:stateObject.bgColor, color: stateObject.color, padding:'0.5em 1em', borderRadius:'1em'}}>
                            <FontAwesomeIcon style={{color:stateObject.color}}
                                             icon={stateObject.icon}/> {!short?stateObject.text:""}
        </span>
    }

    render() {
        return(
            <ul className={"phaseStateInfoBoxList"}>
                {this.worksheet.phases.map((phase, index) => {
                    return <>
                        <li className={this.worksheet.phase === phase.id?"selected":""}>
                            <span className={"title"}>{index+1}. Phase: {phase.title} </span>
                        </li>
                        {(index < this.worksheet.phases.length-1) &&
                            <li>
                                ➡️
                            </li>}
                    </>
                })}
            </ul>
        );
    }
}
export default PhaseStateInfoBox;