import React from 'react';
import './GefaerdungsbeurteilungInput.css';
import {v4 as uuidv4} from "uuid";
import {Badge, Button, Col, Form, Row} from "react-bootstrap";
import SingleSelectInputFormField from "../formFields/SingleSelectInputFormField";
import TextInputFormField from "../formFields/TextInputFormField";
import mechanischeGefaehrdungIcon from "./../../assets/images/icons/mechanische_gefaehrdung.png";
import gefahrenstoffeIcon from "./../../assets/images/icons/gefahrenstoffe.png";
import koerperliche_belastungenIcon from "./../../assets/images/icons/koerperliche_belastungen.png";
import schallIcon from "./../../assets/images/icons/schall.png";

import {
    faIndent,
    faPlus,
    faTrashCan
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import InputComponent from "./InputComponent";
import _ from "lodash";




class GefaerdungsbeurteilungInput extends InputComponent {
    constructor(props) {
        super(props);
        this.component = props.component;
        this.writeModeBasedOnPhase = props.writeModeBasedOnPhase;
        this._handleChange = this._handleChange.bind(this);
        this.updateComponent = props.updateComponent;
        this.renderCell = this.renderCell.bind(this);
        this.insertColumn = this.insertColumn.bind(this);
        this.deleteColumn = this.deleteColumn.bind(this);
        this.updateColumn = this.updateColumn.bind(this);

        this.insertOption = this.insertOption.bind(this);
        this.deleteOption = this.deleteOption.bind(this);
        this.renderOptionRow = this.renderOptionRow.bind(this);
    }

    _handleChange(id,value) {


        this.component.config[id] = value;
        this.updateComponent(this.component);
    };

    _handleDataChange(columnId,rowIndex,fieldIndex,value) {

        if(!this.component.data["rowData"].hasOwnProperty(columnId)) {
            this.component.data["rowData"][columnId] = {};
        }

        if(!this.component.data["rowData"][columnId].hasOwnProperty(rowIndex)) {
            this.component.data["rowData"][columnId][rowIndex] = {};
        }

        if(!this.component.data["rowData"][columnId].hasOwnProperty(rowIndex)) {
            this.component.data["rowData"][columnId][rowIndex][fieldIndex] = {};
        }
        this.component.data["rowData"][columnId][rowIndex][fieldIndex] = value;

        this.updateComponent(this.component);
    };

    static create(){
        return {
            id: uuidv4(),
            type : "gefaehrdungsbeurteilung",
            title: "Gefährdungsbeurteilung",
            data : {
                "commentFunctionChecked" : true,
                "evaluation": true,
                rowData : {

                }
            },
            config: {
                columns: [],
                rows: 3,
                showHeader: false
            }
        };
    }


    renderCell(column, rowIndex,fieldIndex,optionsKey){
        const selectedValue = this._getSelectedValueByIndices(column, rowIndex,fieldIndex,optionsKey);
        let title = "";
        let description = "";

        if(fieldIndex === "massnahme"){
            title = "Maßnahmen / Lösungen"
        }else if(fieldIndex === "gefaehrdung"){
            title = "Mögliche Gefahr"
        }
        return (
            <>
                <TextInputFormField
                    rows={1}
                    title={title}
                    placeholder={"..."}
                    value={selectedValue}
                    onChange={(value) => this._handleDataChange(column.id, rowIndex,fieldIndex,value)}
                    disabled={!this.writeModeBasedOnPhase}
                />
            </>);
    }

    _getSelectedValueByIndices(column, rowIndex,fieldIndex,optionsKey){
        let value = "";
        if(this.component.data["rowData"].hasOwnProperty(column.id)
            && this.component.data["rowData"][column.id].hasOwnProperty(rowIndex)
            && this.component.data["rowData"][column.id][rowIndex].hasOwnProperty(fieldIndex)
        ) {
            value = this.component.data["rowData"][column.id][rowIndex][fieldIndex];
        }else if(column.options[rowIndex] !== undefined){
            value = column.options[rowIndex][optionsKey];
        }
        const selectedValue = value;
        return value;
    }

    renderSelectCell(column, rowIndex,fieldIndex,optionsKey){
        const selectedValue = this._getSelectedValueByIndices(column, rowIndex,fieldIndex,optionsKey);
        return (
            <>
                <SingleSelectInputFormField
                    title={"Ja/Nein"}
                    selectedValue={selectedValue}
                    options={[
                        {name: "Ja", value: "true"},
                        {name: "Nein", value: "false"}
                    ]}
                    onChange={(selectedOption) => {
                        this._handleDataChange(column.id, rowIndex,fieldIndex,selectedOption.value)
                    }}
                    disabled={!this.writeModeBasedOnPhase}
                />
            </>);
    }

    renderTextContentCell(column, rowIndex){

        let value = "";

        if(this.component.data["rowData"].hasOwnProperty(column.id)
            && this.component.data["rowData"][column.id].hasOwnProperty(rowIndex)
        ) {
            value = this.component.data["rowData"][column.id][rowIndex];
        }else if(column.defaultValue != undefined){
            value = column.defaultValue;
        }

        const selectedValue = value;

        if(column.type == 'text'){

            return (<span>{selectedValue}</span>);
        }else if(column.type == 'selectbox'){
            return (
                <span>{selectedValue}</span>
            );
        }else if(column.type == 'rowindex'){
            return (
                <Badge>{rowIndex+1}</Badge>
            );
        }else{
            return "ERROR";
        }
    }

    renderIcon(selectedType){
        if(selectedType == 'mechanische_gefaehrdungen'){
            return <img src={mechanischeGefaehrdungIcon} style={{height: "3em"}}/>
        }else if(selectedType == 'strom'){
            return <img src={mechanischeGefaehrdungIcon} style={{height: "3em"}}/>
        }else if(selectedType == 'schall'){
            return <img src={schallIcon} style={{height: "3em"}}/>
        }else if(selectedType == 'gefahrenstoffe'){
            return <img src={gefahrenstoffeIcon} style={{height: "3em"}}/>
        }else if(selectedType == 'koerperliche_belastungen'){
            return <img src={koerperliche_belastungenIcon} style={{height: "3em"}}/>
        }else{
            return <></>
        }
    }

    renderTitle(selectedType){
        return (
            <table>
                <tr>
                    <td style={{paddingRight:'1em'}}>{this.renderIcon(selectedType)}</td>
                    <td style={{width:'100%'}}>
                        {selectedType === 'mechanische_gefaehrdungen' &&
                            <h3>Mechanische Gefährdungen</h3>
                        }
                        {selectedType === 'strom' &&
                            <h3>Gefährdungen durch Strom</h3>
                        }
                        {selectedType === 'schall' &&
                            <h3>Gefährdungen durch Schall</h3>
                        }
                        {selectedType === 'gefahrenstoffe' &&
                            <h3>Gefährdungen durch Gefahrenstoffe</h3>
                        }
                        {selectedType === 'koerperliche_belastungen' &&
                            <h3>Gefährdungen durch körperliche Belastungen</h3>
                        }
                    </td>
                </tr>
            </table>

        )
    }

    renderColumnRow(column,columnIndex){
        return (
            <div key={column.id}>
                <Row>
                    <Col xs={12} md={7}>



                        <SingleSelectInputFormField
                            selectedValue={this.component.config.columns[columnIndex].type}
                            title={"Gefährdungsart auswählen"}
                            options={[
                                {name: 'Gefährdungen durch Strom', value: 'strom'},
                                {name: 'Mechanische Gefährdungen', value: 'mechanische_gefaehrdungen'},
                                {name: 'Gefährdungen durch Schall', value: 'schall'},
                                {name: 'Gefährdungen durch Gefahrenstoffe', value: 'gefahrenstoffe'},
                                {name: 'Gefährdungen durch körperliche Belastungen', value: 'koerperliche_belastungen'},
                            ]}
                            onChange={(selectedOption) => {
                                this.component.config.columns[columnIndex].type = selectedOption.value;
                                if(this.component.config.columns[columnIndex].options == undefined){
                                    this.component.config.columns[columnIndex].options = [];
                                }
                                this.updateComponent(this.component);
                            }}
                            disabled={!this.writeModeBasedOnPhase}
                        />
                    </Col>
                    <Col xs={12} md={3}>


                    <SingleSelectInputFormField
                            selectedValue={this.component.config.columns[columnIndex].anzahlZeilen?this.component.config.columns[columnIndex].anzahlZeilen:"3"}
                            title={"Anzahl Zeilen"}
                            options={[
                                {name: '1', value: "1"},
                                {name: '2', value: "2"},
                                {name: '3', value: "3"}
                            ]}
                            onChange={(selectedOption) => {
                                this.component.config.columns[columnIndex].anzahlZeilen = selectedOption.value;

                                if(this.component.config.columns[columnIndex].options == undefined){
                                    this.component.config.columns[columnIndex].options = [];
                                }
                                this.updateComponent(this.component);
                            }}
                            disabled={!this.writeModeBasedOnPhase}
                        />
                    </Col>


                    <Col xs={12} md={2}>
                        <Button onClick={() => this.deleteColumn(columnIndex)}>
                            <FontAwesomeIcon icon={faTrashCan} />
                        </Button>
                    </Col>
                </Row>
                    <div>
                        <Row>
                            <Col xs={12}>
                                <Form.Group>
                                    <Form.Label>Beispiele</Form.Label>
                                    <Form.Floating>
                                        {this.component.config.columns[columnIndex].options.length > 0 &&
                                            <div style={{marginBottom: '0.5em'}}>
                                                {this.renderOptionRowHeader()}
                                            </div>
                                        }
                                        {this.component.config.columns[columnIndex].options.map( (option, idx) => {
                                            return this.renderOptionRow(option, columnIndex, idx);
                                        })}
                                    </Form.Floating>
                                    <Button disabled={!this.writeModeBasedOnPhase} onClick={() => this.insertOption(columnIndex)}>Option hinzufügen <FontAwesomeIcon icon={faPlus}/></Button>
                                    <br/><br/>
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>

                {(
                        this.component.config.columns[columnIndex].type == "selectbox"
                        || this.component.config.columns[columnIndex].type == "text"
                    ) &&
                    <div>
                        <Row>
                            <Col xs={12} md={2}></Col>
                            <Col xs={12} md={5}>
                                <Form.Label>
                                    <FontAwesomeIcon icon={faIndent}/> Default-Wert
                                </Form.Label>
                                <TextInputFormField
                                    title={""}
                                    rows={1}
                                    value={this.component.config.columns[columnIndex].defaultValue}
                                    onChange={(value) => {
                                        this.component.config.columns[columnIndex].defaultValue = value;
                                        this.updateComponent(this.component);
                                    }}
                                    description={"Geben Sie hier den Standard-Wert der Spalte ein"}
                                    disabled={!this.writeModeBasedOnPhase}
                                />
                            </Col>
                        </Row>
                    </div>
                }
            </div>
        )
    }

    deleteOption(columnIndex,optionIndex){
        this.component.config.columns[columnIndex].options.splice(optionIndex, 1);
        this.updateComponent(this.component);
    }
    insertOption(columnIndex){
        let id = uuidv4();
        this.component.config.columns[columnIndex].options.push(
            {
                id: id,
                gefaehrdung: "",
                ausgewaehlt: "false",
                massnahme: "",
            }
        );
        this.updateComponent(this.component);
    }

    renderOptionRowHeader(){
        return (
            <Row>
                <Col xs={12} md={5}>
                    Mögliche Gefährdung
                </Col>
                <Col xs={12} md={5}>
                    Maßnahmen / Lösungen
                </Col>
                <Col xs={12} md={2}>
                </Col>
            </Row>
        )
    }
    renderOptionRow(option,columnIndex,optionIndex){
        return (
            <div key={option.id}>
                <Row>
                    <Col xs={12} md={4}>
                        <TextInputFormField
                            rows={1}
                            description={""}
                            value={option.gefaehrdung}
                            onChange={(value) => {
                                this.component.config.columns[columnIndex].options[optionIndex]['gefaehrdung'] = value;
                                //this.updateComponent(this.component);
                            }}
                            disabled={!this.writeModeBasedOnPhase}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <TextInputFormField
                            rows={1}
                            description={""}
                            value={option.massnahme}
                            onChange={(value) => {
                                this.component.config.columns[columnIndex].options[optionIndex]['massnahme'] = value;
                                //this.updateComponent(this.component);
                            }}
                            disabled={!this.writeModeBasedOnPhase}
                        />
                    </Col>
                    <Col xs={12} md={2}>

                    <SingleSelectInputFormField
                            title={""}
                            description={""}
                            selectedValue={option.ausgewaehlt}
                            options={[
                                {name: "Ja", value: "true"},
                                {name: "Nein", value: "false"}
                            ]}
                            onChange={(selectedOption) => {
                                this.component.config.columns[columnIndex].options[optionIndex]['ausgewaehlt'] = selectedOption.value;
                                //this.updateComponent(this.component);
                            }}
                            disabled={!this.writeModeBasedOnPhase}
                        />

                    </Col>
                    <Col xs={12} md={2}>
                        <Button onClick={() => this.deleteOption(columnIndex,optionIndex)} disabled={!this.writeModeBasedOnPhase}>
                            <FontAwesomeIcon icon={faTrashCan} />
                        </Button>
                    </Col>
                </Row>

            </div>
        )
    }

    updateColumn(){

    }

    deleteColumn(columnIndex){
        this.component.config.columns.splice(columnIndex, 1);
        this.updateComponent(this.component);
    }
    insertColumn(){
        var id = uuidv4();
        this.component.config.columns.push(
            {
                id: id,
                type: "mechanische_gefaehrdungen",
                anzahlZeilen: 3,
                name: "",
                options: []
            }
        );
        this.updateComponent(this.component);
    }

    renderEditorView(){
        return (
            <div className={this.component.type}>

                <div>
                        <h3>Gefährdungsarten</h3>
                        <Form.Floating>
                            {this.component.config.columns.map( (column, idx) => {
                                return (
                                    <div>
                                        { this.renderColumnRow(column,idx) }
                                    </div>
                                );
                            })}
                        </Form.Floating>
                        <Button onClick={this.insertColumn}>Gefährdungsart hinzufügen <FontAwesomeIcon icon={faPlus}/></Button>
                </div>
            </div>

        );
    }

    renderInputView(){
        var self = this;
        return (
            <div className="GefaerdungsbeurteilungInput">
                       <>
                            {this.component.config.columns.map( (column, idx) => {
                                return (
                                    <>
                                        {self.renderTitle(column.type)}
                                        <div style={{height:'1em'}}></div>
                                        {this.renderInputTableRowHeader()}
                                        {[...Array(parseInt(column.anzahlZeilen))].map((x, i) => {
                                            return (
                                                <div className={"inputFieldsRow"} >
                                                    <Row key={"row_"+i}>
                                                        <Col xs={9} md={5}>
                                                            {this.renderCell(column,i,'gefaehrdung','gefaehrdung')}
                                                        </Col>
                                                        <Col xs={3} md={2}>
                                                            {this.renderSelectCell(column,i,'ausgewaehlt','ausgewaehlt')}
                                                        </Col>
                                                        <Col xs={12} md={5}>
                                                            {this._getSelectedValueByIndices(column, i,'ausgewaehlt','ausgewaehlt') == "true" &&
                                                                this.renderCell(column,i,'massnahme','massnahme')
                                                            }
                                                        </Col>
                                                    </Row>
                                                </div>

                                            )
                                        })}
                                    </>
                                )
                            })}
                       </>

            </div>
        );
    }

    renderInputTableRowHeader(){
        return (
            <Row className={"tableHeaderRow"}>
                <Col xs={9} md={5}>
                    <h5>Mögliche Gefahr</h5>
                </Col>
                <Col xs={3} md={2}>
                    <h5>Ja/Nein</h5>
                </Col>
                <Col xs={12} md={5}>
                    <h5>Maßnahmen / Lösungen</h5>
                </Col>
            </Row>
        )
    }

    renderInstructorView(){
        var self = this;
        return (
            <div className="GefaerdungsbeurteilungInput">
                <>
                    {this.component.config.columns.map( (column, idx) => {

                        return (
                            <>
                                {self.renderTitle(column.type)}
                                <div style={{height:'1em'}}></div>
                                {this.renderInputTableRowHeader()}
                                {[...Array(parseInt(column.anzahlZeilen))].map((x, i) => {
                                    const gefaehrdung = this._getSelectedValueByIndices(column, i,'gefaehrdung','massnahme');
                                    const ausgewaehlt = this._getSelectedValueByIndices(column, i,'ausgewaehlt','ausgewaehlt');
                                    const massnahme = this._getSelectedValueByIndices(column, i,'massnahme','massnahme');
                                    return (
                                        <Row key={"row_"+i}>
                                            <Col xs={9} md={5}>
                                                {gefaehrdung?gefaehrdung:<i>---</i>}
                                            </Col>
                                            <Col xs={3} md={2}>
                                                {!_.isEmpty(ausgewaehlt)?(ausgewaehlt==="true"?<b>Ja</b>:<i>Nein</i>):"---"}
                                            </Col>
                                            <Col xs={12} md={5}>
                                                {massnahme?massnahme:<i>---</i>}
                                            </Col>
                                        </Row>
                                    )
                                })}
                            </>
                        )
                    })}
                </>

            </div>
        );
    }

    render() {
        try{
            if(this.props.instructorMode){
                return <div>
                    {this.renderInstructorView()}
                </div>
            } else if(this.props.showEditorView){
                return <div>
                    {this.renderEditorView()}
                </div>
            }else{
                return <div>
                    {this.renderInputView()}
                </div>
            }
        }catch (e) {
            return (
                <p>Fehler beim Laden der Gefährdungsbeurteilung</p>
            )
        }

    }

}
export default GefaerdungsbeurteilungInput;