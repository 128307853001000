import React, { Component } from 'react';
import { Button} from "react-bootstrap";


class OnOffButton extends Component {

    constructor(props) {
        super(props);
        this.on = props.on;
        this.onText=props.onText;
        this.offText=props.offText;
        this.onChange = props.onChange;
    }


    render() {
        return(
            <Button style={this.props.style?this.props.style:{}} variant="warning"
                    onClick={() =>
                    {this.on = !this.on;
                    this.onChange(this.on)
                    }
                    }
            >{this.on?this.offText:this.onText}
            </Button>
        )
    }
}


export default OnOffButton;