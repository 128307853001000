import React, {Component} from 'react';

import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import './ProjectEditor.css';
import {Button, Col, Container, Row,  Card, Accordion, Alert} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {
    faCaretDown, faCaretUp,
    faClose,
    faCube, faDeleteLeft,
    faDiagramProject,
    faEye, faPencil,
    faPlusSquare,
    faSave, faSort, faSortDesc,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import DragableComponentIcons from "./DragableComponentIcons";
import ComponentUtil from "./ComponentUtil";
import SingleSelectInputFormField from "./formFields/SingleSelectInputFormField";
import TextInputFormField from "./formFields/TextInputFormField";
import DragableSectionBlock from "./DragableSectionBlock";
import PagePopup from "./PagePopup";
import UserController from "../controller/UserController";
import _ from "lodash";
import WorksheetImageDropzone from "./WorksheetImageDropzone";
import StringUtil from "../util/StringUtil";
import DragableRowsWrapper from "./DragableRowsWrapper";
import APIController from "../controller/APIController";
import objectUtil from "../util/objectUtil";
import OnOffButton from "./OnOffButton";
const {v4 : uuidv4} = require('uuid')


const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "#ccc" : "#fff"
});



class ProjectEditor extends Component {

    constructor(props) {
        super(props);

        if(this.props.project.professionId === undefined){
            this.props.project.professionId = '-1'
        }

        if(this.props.project.files === undefined){
            this.props.project.files = []
        }

        if(this.props.project.phases === undefined){
            this.props.project.phases = [];
        }

        if(this.props.project.isTemplate && !UserController.isInstructorAdmin()){
            throw new Error("Keine Berechtigung Templates zu ändern");
        }


        this.state = {
            project: this.props.project,
            popupProps: {
                title: "",
                body: "",
                show: false,
                showFooter: false
            },
            visibilityKeyMap: {},
            showEditor:false,
            openSections: {},
            phaseButtonDisabled : true,
            sectionButtonDisabled : true,
            projectPhaseButtonDisabled : true,
            sectionsSortable:false,
            componentsSortable:false,
            renderKey: 1
        };

        this.unique_id = uuidv4();

        this.removeSection = this.removeSection.bind(this);
        this.deletePhase = this.deletePhase.bind(this);
        this.onSectionUpdate = this.onSectionUpdate.bind(this);
        this.addSection = this.addSection.bind(this);
        this.addProjectPhase = this.addProjectPhase.bind(this);
        this.handleSaveProject = this.handleSaveProject.bind(this);
        this.handleDeleteProject = this.handleDeleteProject.bind(this);
        this.handleDeleteSection = this.handleDeleteSection.bind(this);
        this.getTemplateName = this.getTemplateName.bind(this);



        this.allTopicOptions = [];
        this.allTopicOptions.push(
            {name: 'Gewerksübergreifend (*)', value: '-1'}
        )
        this.props.allTopics.forEach((topic) => {
            this.allTopicOptions.push(
                {name: topic.professionName, value: topic._id}
            )
        });

        this.newSectionFormRef = React.createRef();
        this.newProjectPhaseFormRef = React.createRef();
    }



    deletePhase = (phaseId) => {
        let prevState = this.state;
        prevState.project.phases = this.state.project.phases.filter(item => item.id !== phaseId);
        this.setState({
            ...prevState,
            renderKey: this.state.renderKey+1
        })
    }

    removeSection = (sectionIndex) => {
        if (window.confirm("Sektion '"+this.state.project.sections[sectionIndex].title+"' wirklich löschen?")) {
            this.handleDeleteSection(this.state.project.sections[sectionIndex].id,sectionIndex);
        }
    }


    toggleVisibilityKey(keyId){
        let visibilityKeyMap = this.state.visibilityKeyMap;
        if (!visibilityKeyMap.hasOwnProperty(keyId)) {
            visibilityKeyMap[keyId] = false;
        }
        visibilityKeyMap[keyId] = !visibilityKeyMap[keyId];
        this.setState(prevState => ({
            ...prevState,
            visibilityKeyMap: visibilityKeyMap,
            renderKey: this.state.renderKey + 1
        }));
    }



    addSection(){
        const project = this.state.project;
        let title;
        if(this.newSectionFormRef.current.getValue() !== ""){
            title = this.newSectionFormRef.current.getValue();
        }
        if(title === undefined){
            this.setState({
                popupProps: {
                    title: "Ups! Das geht so nicht",
                    body: `Bitte Titel der neuen Sektion angeben`,
                    show: true,
                    showFooter: false
                },
                sectionButtonDisabled: true
            });
            return ;
        }
        // some validations if sections are configured having the same name
        if(title === undefined || project.sections.some(sec => sec.title.toLowerCase() === title.toLowerCase())){
            this.newSectionFormRef.current.setValue("");
            this.setState({
                popupProps: {
                    title: "Ups! Das geht so nicht",
                    body: `Sektion mit ähnlichem Namen gefunden für ${title}`,
                    show: true,
                    showFooter: false
                },
                sectionButtonDisabled: true
            });
            return ;
        }
        const newSection = {
            id: uuidv4(),
            title : title,
            description: "",
            components : [],
            phaseSettings: []
        };
        project.sections.push(newSection)
        this.newSectionFormRef.current.setValue("");
        this.setState({
            project,
            sectionButtonDisabled: true
        });
    }


    addProjectPhase(){
        const project = this.state.project;

        let title;
        if(this.newProjectPhaseFormRef.current.getValue() !== ""){
            title = this.newProjectPhaseFormRef.current.getValue();
        }
        // some validations if sections are configured having the same name
        if(project.phases.some(phase => phase.title.toLowerCase() === title.toLowerCase())){
            this.newProjectPhaseFormRef.current.setValue("");
            this.setState({
                popupProps: {
                    title: "Ups! Das geht so nicht",
                    body: `Phase mit ähnlichem Namen gefunden für ${title}`,
                    show: true,
                    showFooter: false
                },
                projectPhaseButtonDisabled: true
            });
            return ;
        }

        const newPhase = {
            id: uuidv4(),
            title : title,
            description: "",
            settings : {}
        };

        project.phases.push(newPhase)
        this.newProjectPhaseFormRef.current.setValue("");
        this.setState({
            project,
            projectPhaseButtonDisabled: true,
            renderKey: this.state.renderKey+1
        });
    }


    onSectionUpdate(section){
        var sectionIndex = null;
        this.state.project.sections.map((sect, secIndex) => {
            if(sect.id == section.id){
                sectionIndex = secIndex;
            }
        });
        const project = this.state.project;
        project.sections[sectionIndex] = section;
        this.setState(prevState => ({
            ...prevState,
            project: project
        }));
    }

    onBeforeCapture = () => {};
    onBeforeDragStart = () => {};
    onDragStart = () => {};
    onDragUpdate = () => {};
    onDragEnd = (result) => {
        // the only one that is required
        const { source, destination } = result;
        // dropped outside the list
        if (!destination) {
            return;
        }

        const project = this.state.project

        if(source.droppableId =="dad-sections" && destination.droppableId =="dad-sections"){
            //CASE Reorder Sections within project

            const resultNew = Array.from(project.sections);
            const [removed] = resultNew.splice(source.index, 1);
            resultNew.splice(destination.index, 0, removed);
            project.sections = resultNew;
        }else if(source.droppableId.startsWith("phase-droppables-") && destination.droppableId.startsWith("phase-droppables-")){

            const resultNew = Array.from(project.phases);
            const [removed] = resultNew.splice(source.index, 1);
            resultNew.splice(destination.index, 0, removed);
            project.phases = resultNew;
        } else if(source.droppableId.startsWith("dad-section-") && destination.droppableId.startsWith("dad-section-")){
            //CASE Reorder Module within project

            let sourceSectionIndex = (source.droppableId+"").replace("dad-section-","");
            let destinationSectionIndex = (destination.droppableId+"").replace("dad-section-","");

            if(sourceSectionIndex === destinationSectionIndex){
                const resultNew = Array.from(project.sections[destinationSectionIndex].components);
                const [removed] = resultNew.splice(source.index, 1);
                resultNew.splice(destination.index, 0, removed);
                project.sections[destinationSectionIndex].components = resultNew;
            }else{
                //change from one section to other section
                const project = this.state.project
                project.sections[destinationSectionIndex].components.splice(
                    destination.index,0, project.sections[sourceSectionIndex].components[source.index]);
                project.sections[sourceSectionIndex].components.splice(source.index, 1);
            }

        }else if(source.droppableId.startsWith("dad-components-")){
            //CASE New Component added to section
            let newComponent = null;
            newComponent = ComponentUtil.createNewComponentByTypeId(result.draggableId);
            if(newComponent != null){
                newComponent.config.openCard = true;
                let destinationSectionIndex = (destination.droppableId+"").replace("dad-section-","");

                if(project.sections[destinationSectionIndex] !== undefined){
                    project.sections[destinationSectionIndex].components.splice(
                        destination.index,0,newComponent);
                }
            }
        }else{

            if(DragableRowsWrapper._getFromLocalStorage(source.droppableId) !== null){
                //only if element is in same dropzone
                if(source.droppableId === destination.droppableId){
                    DragableRowsWrapper.fireOnUpdateReorderEvent(source.droppableId,result.source.index, result.destination.index);
                }
            }
            //NOPE
        }


        this.setState(prevState => ({
            ...prevState,
            project:project,
            renderKey: this.state.renderKey + 1
        }));
    };


    handleDeleteSection(sectionId,sectionIndex){
        let project = this.state.project;
        project.sections = project.sections.filter(function( obj ) {
            return obj.id !== sectionId;
        });
        this.setState(prevState => ({
            ...prevState,
            project: project
        }));
    }


    async handleSaveProject(){
        await APIController.updateWorkingsheetTemplate(this.state.project);
        window.alert("erfolgreich gespeichert")
    }

    async handleDeleteProject(projectId){
        await APIController.deleteWorkingsheetTemplate(projectId);
        console.log("project deleted...");
        window.alert("Arbeitsblatt Template wurde erfolgreich gelöscht");
        window.location.href = "/templates";
    }



    getTemplateName(){
        return this.state.project.templatePath.length > 0 ?
            <span>{this.state.project.templatePath[this.state.project.templatePath.length - 1]}</span> : <></>;
    }
    renderAddPhasesButton(){
            return (
                <>
                    <div style={{display:'inline-block', marginTop:'1em'}}>
                        <TextInputFormField
                            title={""}
                            ref={this.newProjectPhaseFormRef}
                            rows={1}
                            placeholder={"Neue Projektphase"}
                            description={""}
                            onChange={(value) => {
                                let prevState = this.state;
                                prevState.projectPhaseButtonDisabled = (value.length < 1);
                                this.setState({
                                    ...prevState
                                })
                            }}
                            value={""}
                        />
                    </div>
                    <div style={{display:'inline-block'}}>
                        <Button style={{marginLeft:'1em'}} disabled={this.state.projectPhaseButtonDisabled}
                                onClick={this.addProjectPhase} variant="success">
                            <FontAwesomeIcon icon={faPlusSquare} /> Phase hinzufügen</Button>
                    </div>
                </>
            )
    }

    renderPhases(){
        if(this.state.project.phases.length === 0){
            return (
                <Alert key={'warning'} variant={'warning'} style={{marginBottom:0}}>
                    Es wurden keine Phasen im Template definiert. Alle Sektionen sind für den Azubi jederzeit beschreibbar.
                </Alert>
                )
        }
        return (
        <>
        {this.state.project.phases.length > 0 &&
            <Droppable type={this.props.type} className={"droppableInputComponent"} droppableId={"phase-droppables-"+this.unique_id}>
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                    >
                        <div>

                            <>
                                <DragableRowsWrapper  onUpdated={(start,end) => {
                                    this.state.project.phases = DragableRowsWrapper.reorderItems(this.state.project.phases, start,end);
                                    this.setState(prevState => ({
                                        ...prevState,
                                        project: this.state.project,
                                        renderKey: this.state.renderKey + 1
                                    }));

                                }} items={this.state.project.phases.map( (phase, index) => {
                                    return (
                                        <div>
                                            {phase.fromParent ?
                                                <Row>
                                                    <Col lg={2}>
                                                        <span style={{color:'red'}}>➡️ Phase {index + 1}
                                                        </span></Col><Col><span>{phase.title}</span>
                                                </Col>
                                                </Row>
                                                :
                                                <Row>
                                                    <Col lg={2}><span>➡️ Phase {index + 1}</span></Col>
                                                    <Col>
                                                        <TextInputFormField
                                                            title={""}
                                                            rows={1}
                                                            key={"phasenameinput_"+phase.id}
                                                            value={phase.title}
                                                            placeholder={"Name"}
                                                            description={""}
                                                            onChange={(value,e) => {
                                                                this.state.project.phases.find(p => p.id === phase.id).title = value;
                                                                let prevState = this.state;
                                                                this.setState({
                                                                    ...prevState
                                                                })
                                                            }
                                                            }
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Button onClick={() => {
                                                            if (window.confirm("Möchten Sie die Phase wirklich löschen?")) {
                                                                this.deletePhase(phase.id);
                                                            }
                                                        }}
                                                        >
                                                            <FontAwesomeIcon icon={faDeleteLeft} /> Löschen
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            }
                                        </div>
                                    )

                                })} />
                            </>


                            {/*this.state.project.phases.map((phase,index) => {
                                return (
                                    <Draggable key={"pid-"+phase.id} draggableId={"dragid-"+phase.id} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                            >
                                                <div>
                                                    {phase.fromParent ?
                                                        <Row>
                                                            <Col lg={2}>
                                                        <span style={{color:'red'}}>➡️ Phase {index + 1}
                                                        </span></Col><Col><span>{phase.title}</span>
                                                            </Col>
                                                        </Row>
                                                                :
                                                    <Row>
                                                        <Col lg={2}><span>➡️ Phase {index + 1}</span></Col>
                                                        <Col>
                                                            <TextInputFormField
                                                                title={""}
                                                                rows={1}
                                                                key={"phasenameinput_"+phase.id}
                                                                value={phase.title}
                                                                placeholder={"Name"}
                                                                description={""}
                                                                onChange={(value,e) => {
                                                                    this.state.project.phases.find(p => p.id === phase.id).title = value;
                                                                    let prevState = this.state;
                                                                    this.setState({
                                                                        ...prevState
                                                                    })
                                                                }
                                                                }
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <Button onClick={() => this.deletePhase(phase.id)}>
                                                                <FontAwesomeIcon icon={faDeleteLeft} /> Löschen
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                    }
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                );
                            })
                            */}
                        </div>
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        }</>

        );
    }

    render() {
        var eingabeKomponenten = ComponentUtil.getEingabeKomponenten();
        var textKomponenten = ComponentUtil.getTextKomponenten();
        var spezialKomponenten = ComponentUtil.getSpecialKomponenten();
        let project = this.state.project;
        return (
            <DragDropContext
                onBeforeCapture={this.onBeforeCapture}
                onBeforeDragStart={this.onBeforeDragStart}
                onDragStart={this.onDragStart}
                onDragUpdate={this.onDragUpdate}
                onDragEnd={this.onDragEnd}>

            <div className="ProjectPage" style={{width:'100%',height:'1000px',overflow:"scroll"}}>
                <div>
                    <PagePopup
                        onClose={()=>this.setState({popupProps:{show:false}})}
                        key={this.state.popupProps.show}
                        {...this.state.popupProps}>
                    </PagePopup>
                </div>

                <div>
                        <div style={{visibility:this.state.showEditor?'visible':'hidden'}} className={"worksheetComponents worksheetComponentsEditor"}>
                            <div align={"right"}>
                                <div className={"editorButton"} style={{marginBottom:'0.5em'}} onClick={()=>{ this.setState({
                                    showEditor:!this.state.showEditor
                                });
                                }}>
                                    <FontAwesomeIcon icon={this.state.showEditor?faClose:faPencil} /> Schließen
                                </div>
                            </div>
                            <div className={"worksheetComponents_inner container-lg"}>

                                <DragableComponentIcons
                                    type={"inputDragables"}
                                    items={eingabeKomponenten} title={"Eingabe"} />
                                <br />
                                <DragableComponentIcons
                                    type={"inputDragables"}
                                    items={textKomponenten} title={"Text"} />
                                <br />
                                <DragableComponentIcons
                                    type={"inputDragables"}
                                    items={spezialKomponenten} title={"Spezial"} />

                            </div>
                        </div>

                </div>
                <Container>
                    <Row>
                        <div xs={12} sm={12} lg={12}>
                            {this.state.project.isTemplate &&
                                <>
                                    <div className={"worksheet"}>
                                        <Row style={{alignItems:'center'}}>
                                            <Col xs={12} sm={12} lg={6}>
                                                <TextInputFormField
                                                    title={this.state.project.isTemplate?'Titel des Templates':'Arbeitsblatt-Titel'}
                                                    rows={1}
                                                    description={""}
                                                    onChange={(value) => {
                                                        var project = this.state.project;
                                                        project.title = value;
                                                        this.setState(prevState => ({
                                                            ...prevState,
                                                            project: project
                                                        }));
                                                    }}
                                                    value={this.state.project.title}
                                                />
                                            </Col>
                                            <Col xs={12} sm={12} lg={6} style={{display:'flex',justifyContent:'left',alignItems:'center'}}>
                                                <div style={{width:'100%'}}>
                                                    <SingleSelectInputFormField
                                                        title={"Berufsgruppe"}
                                                        selectedValue={this.state.project.professionId}
                                                        description={""}
                                                        placeholder={"Template auswählen"}
                                                        options={this.allTopicOptions}
                                                        onChange={(selectedOption) => {
                                                            let project = this.state.project;
                                                            project.professionId = selectedOption.value;
                                                            this.setState(prevState => ({
                                                                ...prevState,
                                                                project: project
                                                            }));
                                                        }}
                                                    />
                                                </div>


                                            </Col>

                                        </Row>

                                    </div>
                                    <br />
                                </>
                            }

                            <div>
                                <> {UserController.isInstructorAdmin() && this.state.project.template !== null &&
                                    <div style={{marginTop:'1em'}}>
                                        <Row>
                                            <Col xs={12} sm={12} lg={12} style={{display:'flex',justifyContent:'left',alignItems:'center'}}>
                                                <h5 style={{display: 'inline',marginRight:'0.5em'}}>Übergeordnetes Template</h5>
                                            </Col>
                                            <Col xs={12} sm={12} lg={12} style={{display:'flex',justifyContent:'left',alignItems:'center',marginBottom:'1em'}}>
                                                <a style={{textDecoration:'none'}} href={"/editor/"+this.state.project.template}>
                                                    <h2 style={{display: 'inline'}}>
                                                        <FontAwesomeIcon icon={faCube}/> {this.getTemplateName()}
                                                    </h2>
                                                </a>
                                            </Col>
                                        </Row>
                                    </div>
                                }
                                   {!this.state.project.isTemplate &&
                                       <Row>
                                        <Col xs={12} sm={3} lg={3} style={{display:'flex',justifyContent:'left',alignItems:'center'}}>
                                            <h2><FontAwesomeIcon icon={faDiagramProject} /> {this.state.project.projectId}</h2>
                                        </Col>
                                    </Row>
                                   }
                                </>
                            </div>

                            <div className={"worksheet-body"}>
                                {this.state.project.isTemplate != true &&
                                <Accordion style={{marginBottom:'1em'}} className={"pradleAccordion"} activeKey={this.state.visibilityKeyMap['imageDropzone']?'imageDropzone':"-1"}>
                                    <Card>
                                        <Card.Header>
                                            <div onClick={(e) => {e.stopPropagation();this.toggleVisibilityKey('imageDropzone')}} className={"cardHeaderWrapper"}>
                                                <Row>
                                                    <Col xs={10}>
                                                        <h2 className={this.state.visibilityKeyMap['imageDropzone']?"closed":"open"}>Projektskizzen / Grafiken</h2>
                                                    </Col>
                                                    <Col xs={2}>
                                                        <div align={"right"} style={{paddingRight:'1em',fontSize:'1.5em'}}>
                                                            {this.state.visibilityKeyMap['imageDropzone']?
                                                                <FontAwesomeIcon icon={faCaretUp} />:
                                                                <FontAwesomeIcon icon={faCaretDown} />}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey={'imageDropzone'}>
                                            <Card.Body>
                                                <WorksheetImageDropzone files={project.files} onFilesUpdated={(updatedFiles) => {
                                                    this.state.project.files = updatedFiles;
                                                    this.setState(this.state);
                                                }} />
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                                }
                                <div>

                                    <Accordion style={{marginBottom:'1em'}} className={"pradleAccordion"} activeKey={this.state.visibilityKeyMap['phases']?'phases':"-1"}>
                                        <Card>
                                            <Card.Header>
                                                <div onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.setState(prevState => ({
                                                        ...prevState,
                                                        sectionsSortable: false,
                                                        renderKey: this.state.renderKey + 1
                                                    }));

                                                    this.toggleVisibilityKey('phases');


                                                }} className={"cardHeaderWrapper"}>
                                                    <Row>
                                                        <Col xs={10}>
                                                            <h2 className={this.state.visibilityKeyMap['phases']?"closed":"open"}>
                                                                ➡️ Phasen
                                                            </h2>
                                                        </Col>
                                                        <Col xs={2}>
                                                            <div align={"right"} style={{paddingRight:'1em',fontSize:'1.5em'}}>
                                                                {this.state.visibilityKeyMap['phases']?
                                                                    <FontAwesomeIcon icon={faCaretUp} />:
                                                                    <FontAwesomeIcon icon={faCaretDown} />}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Card.Header>
                                            <Accordion.Collapse key={this.state.renderKey} eventKey={'phases'}>
                                                <Card.Body>
                                                    <>
                                                    {this.renderPhases()}
                                                        {this.state.project.isTemplate &&
                                                            this.renderAddPhasesButton()
                                                        }
                                                    </>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>

                                </div>
                                <div>
                                    <OnOffButton key={this.state.renderKey} style={{marginBottom:'0.5em'}} onChange={(on) => this.setState(prevState => ({
                                        ...prevState,
                                        sectionsSortable: on,
                                        renderKey: this.state.renderKey+1
                                    }))} onText={<><FontAwesomeIcon icon={faSort} /> Sortieren</>} offText={<><FontAwesomeIcon icon={faClose} /> Sortierung beenden</>} on={this.state.sectionsSortable} />
                                </div>

                                {this.state.sectionsSortable?
                                    <>
                                        <Droppable type={"sectionDragable"} droppableId={"dad-sections"}>
                                            {(provided, snapshot) => (
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                    className={snapshot.isDraggingOver?"sectionsDropZone isDraggingOver":"sectionsDropZone"}
                                                >
                                                    <div className={"sections"}>
                                                        {project.sections.map((section, sectionIndex) => {
                                                            return (
                                                                <DragableSectionBlock
                                                                    section={section}
                                                                    sectionsSortable = {this.state.sectionsSortable}
                                                                    componentsSortable = {this.state.componentsSortable}
                                                                    showComponents={!this.state.visibilityKeyMap['phases']}
                                                                    allPossiblePhases={this.state.project.phases}
                                                                    key={"drag-sec-"+section.id+"-"+this.state.renderKey}
                                                                    removeSection={()=>this.removeSection(sectionIndex)}
                                                                    onSectionUpdate={this.onSectionUpdate}
                                                                    sectionIndex={sectionIndex} />
                                                            );
                                                        })}
                                                    </div>
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </>
                                    :
                                    <>
                                        <div className={"sections"}>
                                            {project.sections.map((section, sectionIndex) => {
                                                return (
                                                    <DragableSectionBlock
                                                        section={section}
                                                        sectionsSortable = {this.state.sectionsSortable}
                                                        componentsSortable = {this.state.componentsSortable}
                                                        showComponents={!this.state.visibilityKeyMap['phases']}
                                                        allPossiblePhases={this.state.project.phases}
                                                        key={"drag-sec-"+section.id+"-"+this.state.renderKey}
                                                        removeSection={()=>this.removeSection(sectionIndex)}
                                                        onSectionUpdate={this.onSectionUpdate}
                                                        sectionIndex={sectionIndex} />
                                                );
                                            })}
                                        </div>
                                    </>
                                }

                            </div>
                            <div className={"worksheet"} style={{marginBottom:'5em'}}>
                                <Row>
                                    <Col xs={12} lg={12}>
                                        <div style={{display:'flex',justifyContent:'left',alignItems:'center'}}>

                                            <div style={{display:'inline-block'}}>
                                                <TextInputFormField
                                                    title={""}
                                                    ref={this.newSectionFormRef}
                                                    rows={1}
                                                    placeholder={"Neuer Sektionsname"}
                                                    description={""}
                                                    onChange={(value) => {
                                                        let prevState = this.state;
                                                        prevState.sectionButtonDisabled = (value.length < 1);
                                                        //this.setState({
                                                         //   ...prevState
                                                        //})
                                                    }}
                                                    value={""}
                                                />
                                            </div>
                                            <div style={{display:'inline-block'}}>
                                                <Button style={{marginLeft:'1em'}}
                                                        onClick={this.addSection} variant="success"><FontAwesomeIcon icon={faPlusSquare} /> Sektion</Button>
                                            </div>

                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <br />
                            <div className={"worksheetFooter"}>
                                <Row>
                                    <Col xs={12} md={6}>
                                        <div align={"left"}>
                                            <Button style={{marginLeft:'1em'}} onClick={() =>
                                            {
                                                if (window.confirm("Möchten Sie das Arbeitsblatt-Template wirklich löschen?")) {
                                                    this.handleDeleteProject(this.state.project.id);
                                                }

                                            }
                                               } variant="warning"><FontAwesomeIcon icon={faTrash} /> Löschen</Button>
                                            <div className={"editorButton"} style={{display:'inline-block',marginLeft:'1em'}} onClick={()=>{ this.setState({
                                                showEditor:!this.state.showEditor
                                            });
                                            }}>
                                                <FontAwesomeIcon icon={this.state.showEditor?faClose:faPencil} /> {!this.state.showEditor?'Menü anzeigen':'Menü schließen'}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <div align={"right"}>
                                            <Button style={{marginRight:'1em'}} onClick={() => {
                                                if (window.confirm("Sie verlassen den Editor Modus. Haben Sie Ihre Änderungen gespeichert?")) {
                                                    window.location.href = "/project/"+this.state.project.id}
                                                }
                                            } variant="success"><FontAwesomeIcon icon={faEye} /> Vorschau</Button>
                                            <Button onClick={this.handleSaveProject} variant="success"><FontAwesomeIcon icon={faSave} /> Speichern</Button>
                                        </div>
                                    </Col>

                                </Row>
                            </div>
                        </div>

                    </Row>
                </Container>
            </div>
            </DragDropContext>
        );
    }
}


export default ProjectEditor;