import React from 'react';
import {v4 as uuidv4} from "uuid";
import {Alert, Col, Row} from "react-bootstrap";
import NumberInputFormField from "../formFields/NumberInputFormField";
import InputComponent from "./InputComponent";
import '@mdxeditor/editor/style.css'
import {
    MDXEditor,
    headingsPlugin,
    listsPlugin,
    quotePlugin,
    thematicBreakPlugin,
    tablePlugin,
    toolbarPlugin,
    BoldItalicUnderlineToggles,
    UndoRedo,
    InsertTable,
    ListsToggle,
    BlockTypeSelect,
    linkPlugin,
    linkDialogPlugin,
    codeBlockPlugin,
    markdownShortcutPlugin,
    directivesPlugin,
    KitchenSinkToolbar,
    AdmonitionDirectiveDescriptor,
    CreateLink, InsertAdmonition, InsertThematicBreak
} from '@mdxeditor/editor'
import _ from "lodash";

class MarkdownTextComponent extends InputComponent {
    constructor(props) {
        super(props);
        this.component = props.component;
        this.writeModeBasedOnPhase = props.writeModeBasedOnPhase;
        this._handleDataChange = this._handleDataChange.bind(this);
        this.updateComponent = props.updateComponent;
        this.markdownEditorRef = React.createRef();
        this.state = {
            value: props.value
        };

    }

    _handleDataChange() {
        this.component.data['text'] = this.markdownEditorRef.current?.getMarkdown();
    };


    static create(){
        return {
                id: uuidv4(),
                type : "markdownText",
                title: "Markdown Text",
                data : {
                    text: "",
                },
                config: {
                    commentFunctionChecked : true,
                    evaluationChecked: true,
                    description: ""
                }
            }
    }

    // We need to spread the previous state and change the one we're targeting, so other data cannot be lost.


    renderEditorView(){
        return (
            <div>
                <Row>
                    <Col xs={12} md={12}>
                        <MDXEditor
                            ref={this.markdownEditorRef}
                            markdown={this.component.data.text}
                            onChange={this._handleDataChange}

                            plugins={[
                                tablePlugin(),
                                listsPlugin(),
                                headingsPlugin(),
                                linkDialogPlugin(),
                                linkPlugin(),
                                thematicBreakPlugin(),
                                directivesPlugin({ directiveDescriptors: [AdmonitionDirectiveDescriptor] }),
                                toolbarPlugin({
                                    toolbarContents: () => (
                                        <>
                                            <UndoRedo />
                                            <BlockTypeSelect />
                                            <InsertAdmonition />
                                            <BoldItalicUnderlineToggles />
                                            <CreateLink />
                                            <ListsToggle />
                                            <InsertTable />
                                            <InsertThematicBreak />
                                        </>
                                    )
                                })
                            ]}
                        />
                    </Col>
                </Row>
            </div>
        );
    }

    renderInputView(){
        return (
            <div className={this.component.type}>
                <MDXEditor
                    style={{padding:'0em'}}
                    ref={this.markdownEditorRef}
                    markdown={this.component.data.text}
                    onChange={this._handleDataChange}
                    readOnly={!this.writeModeBasedOnPhase}
                    plugins={[
                        directivesPlugin({ directiveDescriptors: [AdmonitionDirectiveDescriptor] }),
                        listsPlugin(),
                        headingsPlugin(),
                        quotePlugin(),
                        codeBlockPlugin(),
                        quotePlugin(),
                        markdownShortcutPlugin()
                    ]}
                />
            </div>
        );
    }

    renderInstructorView(instructorMode){
        return (
            <div className={this.component.type}>
                <div>
                    <MDXEditor
                        style={{padding:'0em'}}
                        ref={this.markdownEditorRef}
                        markdown={this.component.data.text}
                        onChange={this._handleDataChange}
                        readOnly={true}
                        plugins={[
                            directivesPlugin({ directiveDescriptors: [AdmonitionDirectiveDescriptor] }),
                            tablePlugin(),
                            linkPlugin(),
                            thematicBreakPlugin(),
                            listsPlugin(),
                            headingsPlugin()
                        ]}
                    />
                </div>
            </div>
        );
    }

    render() {

        if (this.props.showEditorView) {
            return <div>
                {/*JSON.stringify(this.component)*/}
                {this.renderEditorView()}
            </div>
        } else {
            if (this.props.instructorMode) {
                return <div>
                    {/*JSON.stringify(this.component)*/}
                    {this.renderInstructorView()}
                </div>
            }
            else {
                    return <div>
                        {/*JSON.stringify(this.component)*/}
                        {/*TODO: Is this intentional, to render always InstructorView?*/this.renderInstructorView()}
                    </div>
                }
            }
        }

}
export default MarkdownTextComponent;