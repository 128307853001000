import React, { Component } from 'react';
import {Accordion, Card, Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
     faCaretDown, faCaretUp
} from "@fortawesome/free-solid-svg-icons";
import '../components/WorksheetViewComponent.css';
import ComponentBlock from "./ComponentBlock";
import ActionButton from "./input/ActionButton";
import _ from "lodash";
import UserController from "../controller/UserController";
import PhaseStateInfoBox from "./PhaseStateInfoBox";
import StatusBadge from "./badges/StatusBadge";



class WorksheetViewComponent extends Component {

    constructor(props) {
        super(props);
        this.readOnlyMode = props.readOnlyMode;
        this.instructorMode = props.instructorMode;
        this.showEditorView = props.showEditorView;
        this.onWorksheetUpdated = props.onWorksheetUpdated;
        this.onCommentsUpdated = props.onCommentsUpdated;
        this.topicId = props.topicId;
        this.trainee = props.trainee;

        this.state = {
            openSections: {},
            worksheet: props.worksheet,
            sections: props.sections
        };
        this._onComponentUpdate = this._onComponentUpdate.bind(this);
        this.onComponentUpdate = props.onComponentUpdate!=null?props.onComponentUpdate:this._onComponentUpdate;
    }

    toggleSection(sectionId){
        if (!this.state.openSections.hasOwnProperty(sectionId)) {
            this.state.openSections[sectionId] = false;
        }
        let openSections = this.state.openSections;
        openSections[sectionId] = !this.state.openSections[sectionId];
        this.setState({
            openSections
        });
    }


    _onComponentUpdate(component){
        var sectionIndex = null;
        var componentIndex = null;
        this.state.sections.map((section, secIndex) => {
            section.components.map( (comp, compIndex) => {
                if(comp.id == component.id){
                    sectionIndex = secIndex;
                    componentIndex = compIndex;
                }
            });
        });
        let sections = this.state.sections;
        sections[sectionIndex].components[componentIndex] = component;
        this.setState({
            sections:sections
        });
    }

    render() {
        return (
            <>
                <div className="worksheetView">
                    <Row>
                        <Col xs={12} sm={12} lg={12}>
                            <div className={"my-worksheet"}>
                                <div className={"sections"}>
                                    {this.state.sections.map((section, sectionIndex) => {
                                        let sectionIsVisible = true;
                                        let sectionIsWritable = false;
                                        if(this.state.worksheet != null) {
                                            switch (this.state.worksheet.state) {
                                                case StatusBadge.TYPES.OPEN_WORKING_SHEET.state:
                                                    sectionIsVisible = PhaseStateInfoBox.isVisibleSectionByPermission(this.state.worksheet, section.id);
                                                    sectionIsWritable = PhaseStateInfoBox.isWritableSectionByPermission(this.state.worksheet, section.id);
                                                    break;
                                                case StatusBadge.TYPES.SUBMITTED_WORKING_SHEET.state:
                                                    sectionIsVisible = PhaseStateInfoBox.isVisibleSectionByPermission(this.state.worksheet, section.id);
                                                    sectionIsWritable = false;
                                                    break;
                                                case StatusBadge.TYPES.GRADED_WORKING_SHEET.state:
                                                    sectionIsVisible = true;
                                                    sectionIsWritable = false;
                                                    break;
                                                case StatusBadge.TYPES.DRAFT_WORKING_SHEET.state:
                                                    sectionIsVisible = false;
                                                    sectionIsWritable = false;
                                                    break;
                                                case StatusBadge.TYPES.DISCARDED_WORKING_SHEET.state:
                                                    sectionIsVisible = false;
                                                    sectionIsWritable = false;
                                                    break;
                                                default:
                                                    sectionIsVisible = true;
                                                    sectionIsWritable = false;
                                                    break;
                                            }
                                        }
                                        let readablePermission = PhaseStateInfoBox.getHumanStringByPhaseValue(sectionIsWritable?"W":"R");
                                        if (sectionIsVisible && !(section.hide !== undefined && section.hide === true)) {
                                            return (
                                                <div key={sectionIndex} className={"section-wrapper"}>
                                                    <div className={"section-header"}>
                                                        <Row>
                                                            <Col xs={10}>
                                                                <h2 className={this.state.openSections[section.id] ? "closed" : "open"}>{section.title}</h2>
                                                                <span style={{color:'white'}}>{readablePermission}</span>
                                                            </Col>
                                                            <Col xs={2}>
                                                                <div align={"right"} style={{paddingRight: '1em'}}>
                                                                    <ActionButton handler={() => {
                                                                        this.toggleSection(section.id)
                                                                    }}>
                                                                        {
                                                                            this.state.openSections[section.id] ?
                                                                                <FontAwesomeIcon
                                                                                    icon={faCaretDown}/> :
                                                                                <FontAwesomeIcon icon={faCaretUp}/>
                                                                        }
                                                                    </ActionButton>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <div className={"section"} key={sectionIndex}>
                                                        {!section.hide &&
                                                            <Accordion className={"sectionAccordion"}
                                                                       activeKey={this.state.openSections[section.id] ? "-1" : "section-accordion-" + section.id}>
                                                                <Card>
                                                                    <Accordion.Collapse
                                                                        eventKey={"section-accordion-" + section.id}>
                                                                        <Card.Body
                                                                            className={"sectionAccordionCardBody"}>
                                                                            <div className={"components"}>
                                                                                <Row>
                                                                                    {section.components.map((component, index) => {
                                                                                        return (
                                                                                            <Col key={index}
                                                                                                 xs={component.config.size ? component.config.size : 12}>
                                                                                                {!component.hide &&
                                                                                                    <ComponentBlock
                                                                                                        key={component.id}
                                                                                                        onRemoveComponentClicked={() => {
                                                                                                        }}
                                                                                                        component={component}
                                                                                                        onComponentUpdate={this.onComponentUpdate}
                                                                                                        componentIndex={index}
                                                                                                        writeModeBasedOnPhase={sectionIsWritable}
                                                                                                        readOnlyMode={this.readOnlyMode}
                                                                                                        instructorMode={this.instructorMode}
                                                                                                        showEditorView={this.showEditorView}
                                                                                                        onCommentsUpdated={this.onCommentsUpdated}
                                                                                                    />
                                                                                                }
                                                                                            </Col>
                                                                                        )
                                                                                    })}
                                                                                </Row>
                                                                                <Row>
                                                                                    {section.components.length === 0 &&
                                                                                        <Col xs={12}>
                                                                                            <div
                                                                                                className={"no-components"}
                                                                                                style={{
                                                                                                    padding: '1em',
                                                                                                    textAlign: "center"
                                                                                                }}>
                                                                                                <p>Keine Komponenten
                                                                                                    in diesem
                                                                                                    Abschnitt</p>
                                                                                            </div>
                                                                                        </Col>
                                                                                    }
                                                                                </Row>
                                                                            </div>
                                                                        </Card.Body>
                                                                    </Accordion.Collapse>
                                                                </Card>
                                                            </Accordion>
                                                        }
                                                    </div>
                                                </div>
                                            );
                                        }
                                    } )}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}


export default WorksheetViewComponent;