import React, {Component} from "react";
import LoadingScreen from "../../includes/LoadingScreen";
import UserController from "../../controller/UserController";
import APIController from "../../controller/APIController";
import {faDiagramProject, faFileWord} from "@fortawesome/free-solid-svg-icons";
import PageContent from "../../includes/PageContent";
import './WorksheetListPageForTrainee.css';
import CenteredMessage from "../../includes/CenteredMessage";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Col, Container, Row} from "react-bootstrap";
import he from "he";
import image1 from "../../assets/topic_images/1.jpg";
import PradleAccordion from "../../components/PradleAccordion";
import SmartWorksheetActionButtons from "../../components/WorksheetEditorComponents/SmartWorksheetActionButtons";
import StatusBadge from "../../components/badges/StatusBadge";
import DateTimeUtil from "../../util/DateTimeUtil";
import WorksheetUtil from "../../util/WorksheetUtil";
import defaultImageUrl from "../../assets/topic_images/1.jpg";
import PhaseStateInfoBox from "../../components/PhaseStateInfoBox";
import TopicKachel from "../../components/TopicKachel";

class WorksheetsListPageForTrainee extends Component {


    constructor(props) {
        super(props);
        this.state = {
            isFetching: true,
            user: UserController.getUser(),
            traineeId: props.traineeId,
            worksheets: [],
            worksheetsGroupedByCourseId: {},
            worksheetsGroupedByTopicId: {},
            ltbView: props.lbtView
        }
        this.onWorksheetsUpdated = this.onWorksheetsUpdated.bind(this);
    }

    async componentDidMount() {
        try {
            let user = this.state.user;
            /*  if(this.state.traineeId !== user.id){
                alert("No Access possible");
                window.location = UserController.getMyProfileUrl();
            }*/
            const traineeId = encodeURIComponent(this.state.traineeId);
            let courses = await APIController.getWorksheetByParam("traineeId", traineeId);

            if(this.props.groupNameFilter !== undefined){
                let str = this.props.groupNameFilter;
                // Ersetzen von '*' mit '.*'
                str = str.replace(/\*/g, '.*');
                // Voranfügen von '/^' und Anhängen von '$/'
                let pattern = new RegExp("^" + str + "$");
                courses = courses.filter(obj => pattern.test(obj.name));
            }


            if(this.props.topicFilter !== undefined){
                let str = this.props.topicFilter;
                // Ersetzen von '*' mit '.*'
                str = str.replace(/\*/g, '.*');
                // Voranfügen von '/^' und Anhängen von '$/'
                let pattern = new RegExp("^" + str + "$");
                courses.forEach(function(part, index, theArray) {
                    courses[index].worksheets = courses[index].worksheets.filter(obj => pattern.test(obj.topic));
                });

            }


            if(this.props.course !== undefined){
                courses.forEach(function(part, index, theArray) {
                    courses[index].worksheets = courses[index].worksheets.filter(obj => obj.topic === this.props.topicFilter);
                });
            }

            courses = courses.filter(c => c.worksheets.length > 0);


            this.setState({
                isFetching: false,
                courses: courses
            })
        } catch (e) {
            console.log(e);
            this.setState({
                isFetching: false,
                courses: []
            })
        }
    }

    onWorksheetsUpdated(updatedWorksheets){
        window.alert(JSON.stringify(updatedWorksheets))
        let allworksheets = [];
        Object.keys(updatedWorksheets).map((course, sectionIndex) => {
            updatedWorksheets[course].map((w) => {
                allworksheets.push(w);
            });
        });
        this.setState({
            worksheets: allworksheets
        });
    }


    _getTitleImage(worksheet){
        let filesArray = [];
        if(worksheet.hasOwnProperty('files')){
            filesArray = worksheet.files;
        }
        let url =  WorksheetUtil.findPrimaryTitleImageUrl(filesArray);
        if(url == null){
            url = defaultImageUrl;
        }
        return <img style={{background:'white'}} className={"topicImage"} src={url} />
    }

    render() {

        if(this.state.isFetching){
            return <LoadingScreen/>;
        }


        if(this.state.courses.length === 0){
            return (
                <PageContent>
                    {this.state.ltbView?
                        <CenteredMessage>
                        <FontAwesomeIcon icon={faFileWord} />
                        Du hast keine Arbeitsblätter
                    </CenteredMessage>
                        :
                        <CenteredMessage>
                            <FontAwesomeIcon icon={faFileWord} />
                            Es wurden Keine Arbeitsblätter gefunden.
                            <h4>Hast du dich ordnungsgemäß bei dem Digitalen Arbeitsblatt eingeloggt?</h4>
                            <span>Bitte prüfen und ggf. <a href={"/login"}>hier</a> einloggen.</span>
                        </CenteredMessage>
                    }
                </PageContent>
            )
        }

        return(
                <PageContent>
                <div className={"WorksheetsListPageForTrainee"}>
                        {this.state.courses.map((course,idx)=>{
                            return(
                                <div key={"WorksheetsListPageForTrainee_div_"+idx}>
                                    {/*this.state.trainee.courses.find((obj) => obj._id === connectedTopic._id).name*/}
                                    <PradleAccordion title={<div><FontAwesomeIcon icon={faDiagramProject} /> {course.name}</div>} keyId={"topics"} open={(idx===0)}>
                                        <h3>{DateTimeUtil.parseIsoDateOfYear(course.start)} - {DateTimeUtil.parseIsoDateOfYear(course.end)}</h3>
                                        <Row>
                                            {course.worksheets.map((ws,idx)=>{
                                                if(ws.state === APIController.WORKSHEET_STATES.DISCARDED_WORKING_SHEET){
                                                    /* Hide worksheets, which are discardedQ */
                                                    //TODO: Put this filter operation in API, instead of frontend
                                                    return <></>;
                                                }
                                                return (
                                                 <Col xs={12} sm={6} lg={4} xl={3}>
                                                        <TopicKachel
                                                            topicId={ws.topic}
                                                            topicName={(ws.description.taskName!=="" && ws.description.taskName!==null)?ws.description.taskName:"Projekt "+ws.topic}
                                                            courseId={ws.courseId}
                                                            worksheets={[ws]}
                                                        ></TopicKachel>
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                    </PradleAccordion>

                                </div>
                            );
                        })}
                    </div>
                </PageContent>

            )

    }
}

export default WorksheetsListPageForTrainee;