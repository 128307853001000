import React, {Component} from 'react';
import {Draggable, Droppable} from "react-beautiful-dnd";
import {Accordion, Button, Card, Col, Dropdown, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './DragableSectionBlock.css';
import {
    faCaretDown,
    faCaretUp, faClose, faEye, faEyeSlash, faPlusSquare,
} from "@fortawesome/free-solid-svg-icons";
import DragableComponentBlock from "./DragableComponentBlock";
import ComponentUtil from "./ComponentUtil";
import SingleSelectInputFormField from "./formFields/SingleSelectInputFormField";
import PhaseStateInfoBox from "./PhaseStateInfoBox";
import OnOffButton from "./OnOffButton";

class DragableSectionBlock extends Component {
    constructor(props) {
        super(props);
        this.section = props.section;
        this.allPossiblePhases = props.allPossiblePhases;
        this.onSectionUpdate = props.onSectionUpdate
        this.removeSection = props.removeSection;
        this.onSectionUpdate = props.onSectionUpdate;
        this.sectionIndex = props.sectionIndex;
        this.showComponents = props.showComponents;
        this.removeComponent = this.removeComponent.bind(this);
        this.onComponentUpdate = this.onComponentUpdate.bind(this);
        this.addComponent = this.addComponent.bind(this);
        this.renderPhaseInputFields = this.renderPhaseInputFields.bind(this);
        this.renderComponentsList = this.renderComponentsList.bind(this);

        this.sectionsSortable = props.sectionsSortable;
        this.componentsSortable = props.componentsSortable;
        this.state = {
            openSections: {},
            renderKey: 1
        };
    }



    onComponentUpdate(component){
        var componentIndex = null;
        this.section.components.map( (comp, compIndex) => {
            if(comp.id == component.id){
                componentIndex = compIndex;
            }
        });
        this.section.components[componentIndex] = component;
        this.updateSection(this.section);
    }

    removeComponent = (componentIndex) => {
        if (window.confirm("Komponente '"+this.section.components[componentIndex].title+"' wirklich löschen?")) {
            const section = this.section;
            section.components.splice(componentIndex, 1);
            this.updateSection(section);
        }
    }

    hideParentSectionClicked = () => {
        this.section.hide = !this.section.hide;
        this.updateSection(this.section);
    }

    toggleSection(sectionId){
        if (!this.state.openSections.hasOwnProperty(sectionId)) {
            this.state.openSections[sectionId] = false;
        }
        let openSections = this.state.openSections;
        openSections[sectionId] = !this.state.openSections[sectionId];
        this.setState({
            ...
            openSections
        });
    }

    updateSection(section){
        this.onSectionUpdate(section);
        console.log(section, " Check after Section Updated from onchange in DragableSectionBlock");
    }

    addComponent(componentTypeId){
        let newComponent = ComponentUtil.createNewComponentByTypeId(componentTypeId);
        this.section.components.push(newComponent);
        this.updateSection(this.section);
    }

    renderAddComponentDropdown(title,eingabeKomponentenListe,textKomponentenListe,disabled=false){
        return <Dropdown key={"Dropdown_"+title}>
            <Dropdown.Toggle key={"Dropdown.Toggle_"+title} variant="success" id="dropdown-basic">
                {title}
            </Dropdown.Toggle>
            <Dropdown.Menu key={"Dropdown.Menu_"+title}>
                <Dropdown.Header>Eingabe-Komponenten</Dropdown.Header>
                {eingabeKomponentenListe.map((componentType,idx) => {
                    return <>
                        <Dropdown.Item key={idx} disabled={disabled} onClickCapture={() =>  {this.addComponent(componentType.id)}}>
                            <span key={"span_"+idx}>{ComponentUtil.getIconByComponentType(componentType.id)} {ComponentUtil.getTitleByComponentType(componentType.id)}</span>
                        </Dropdown.Item>
                    </>
                })}
                <Dropdown.Divider />
                <Dropdown.Header>Text-Komponenten</Dropdown.Header>
                {textKomponentenListe.map((componentType,idx) => {
                    return <>
                        <Dropdown.Item key={idx} disabled={disabled} onClickCapture={() =>  {this.addComponent(componentType.id)}}>
                            <span key={"span_"+idx}>{ComponentUtil.getIconByComponentType(componentType.id)} {ComponentUtil.getTitleByComponentType(componentType.id)}</span>
                        </Dropdown.Item>
                    </>
                })}
            </Dropdown.Menu>
        </Dropdown>
    }


    recalculatePhasesStates(){
        if(this.section.phaseSettings === undefined){
            this.section.phaseSettings = [];
        }
        this.allPossiblePhases.forEach(phase => {
                if (!this.section.phaseSettings.some(ps => ps.id === phase.id)) {
                    this.section.phaseSettings.push({
                        id: phase.id,
                        value: "N",
                        fromParent: false
                    });
                } else {
                    //let objectToUpdate = this.section.phaseSettings.find(ps => ps.id === phase.id);
                    //objectToUpdate.fromParent = false;
                }
            }
        );
    }



    renderPhaseInputFields(){
        let counter = 1;
        let allOptions = [
            {name: PhaseStateInfoBox.getHumanStringByPhaseValue("R"), value: "R"},
            {name: PhaseStateInfoBox.getHumanStringByPhaseValue("W"), value: "W"},
            {name: PhaseStateInfoBox.getHumanStringByPhaseValue("H"), value: "H"},
            {name: PhaseStateInfoBox.getHumanStringByPhaseValue("N"), value: "N"}
        ]
        return (<Row>
            {this.allPossiblePhases.map((phase) => {
                let currentVal = "N";
                let currentValFromParent = false;
                this.section.phaseSettings.filter(s => s.fromParent === undefined || !s.fromParent).forEach(settings => {
                    if(settings.id === phase.id){
                        currentVal = settings.value;
                        currentValFromParent = false;
                    }
                });
                this.section.phaseSettings.filter(s => (s.fromParent !== undefined && s.fromParent === true)).forEach(settings => {
                    if(settings.id === phase.id && settings.value !== "N"){
                        currentVal = settings.value;
                        currentValFromParent = true;
                    }
                });
                const self = this;
                if(currentValFromParent){
                    return (
                        <Col xs={12} sm={6} lg={4}>
                            <span style={{color:phase.fromParent?'red':'#333'}}>
                                ➡️ Phase {counter++}: {phase.title}
                                <br />
                                <div style={{padding:'0.5em', background:'#ddd'}}>
                                    {PhaseStateInfoBox.getHumanStringByPhaseValue(currentVal)}
                                </div>
                            </span>
                        </Col>
                    )
                }

                return (

                    <Col xs={12} sm={6} lg={4}>
                          <span style={{color:phase.fromParent?'red':'#333'}}>
                            ➡️ Phase {counter++}: {phase.title}
                        </span>
                        <SingleSelectInputFormField
                            selectedValue={currentVal}
                            options={allOptions}
                            onChange={(selectionOption) => {
                                let phaseSetting = self.section.phaseSettings.find(p => p.id === phase.id);
                                if(phaseSetting === undefined){
                                    phaseSetting = {
                                        id: phase.id,
                                        value: selectionOption.value,
                                        fromParent: false
                                    };
                                    self.section.phaseSettings.push(phaseSetting)
                                }else{
                                    phaseSetting.value = selectionOption.value;
                                    phaseSetting.fromParent = false;
                                }
                                self.onSectionUpdate(self.section);
                            }}
                        />
                    </Col>
                );
            })}
        </Row>);
    }

    renderSectionCardHeader(){
        return(
            <Card.Header className={"sectionAccordionCardheader"}>
                <Row>
                    <Col xs={8}>
                        <h2 className={this.state.openSections[this.section.id]?"closed":"open"}>{this.section.title}</h2>
                    </Col>
                    {this.showComponents &&
                        <Col xs={4}>
                            <div align={"right"} style={{paddingRight:'1em'}}>
                                {!this.section.hide &&
                                    <Button onClick={() => {this.toggleSection(this.section.id)}}>
                                        {
                                            this.state.openSections[this.section.id]?
                                                <FontAwesomeIcon icon={faCaretDown} />:
                                                <FontAwesomeIcon icon={faCaretUp} />
                                        }
                                    </Button>
                                }
                                {this.section.fromParent &&
                                    <Button style={{marginLeft:"0.5em"}} onClick={() => {this.hideParentSectionClicked()}}>
                                        {!this.section.hide?
                                            <FontAwesomeIcon icon={faEye} />
                                            :<FontAwesomeIcon icon={faEyeSlash} />
                                        }
                                    </Button>
                                }
                                {!this.section.fromParent &&
                                    <div style={{display:'inline-block'}}>
                                        <Button style={{marginLeft:"0.5em"}} onClick={() => {this.removeSection(this.sectionIndex)}}><FontAwesomeIcon icon={faClose} /></Button>
                                    </div>
                                }

                            </div>
                        </Col>
                    }
                </Row>
            </Card.Header>
        )
    }



    renderSectionAccording(){
        this.recalculatePhasesStates();


        if(this.props.sectionsSortable){
            return(<>
                {this.renderComponentsList()}
                </>)
        }

        return (
            <div className={`${'section'} ${this.section.hide?'hided':' '}`}>
                <Accordion  className={`${'sectionAccordion'} ${this.section.hide?'hided':' '}`} activeKey={this.state.openSections[this.section.id]?"-1":"section-accordion-"+this.section.id}>
                    <Card>
                        {this.renderSectionCardHeader()}
                        {!this.section.hide &&
                        <Accordion.Collapse eventKey={"section-accordion-"+this.section.id}>
                            <Card.Body className={"sectionAccordionCardBody"}>
                                <>
                                    {this.showComponents &&
                                        <>
                                            {this.renderComponentsList()}
                                            {this.renderAddComponentsButtons()}
                                        </>
                                    }
                                    {!this.showComponents &&
                                        <div style={{marginBottom:'1em'}}>
                                            {this.renderPhaseInputFields()}
                                        </div>
                                    }
                                </>
                            </Card.Body>
                        </Accordion.Collapse>
                        }
                    </Card>
                </Accordion>
            </div>
        )
    }


    renderAddComponentsButtons(){
        return(
            <div>
                <div style={{display:'inline-block',marginRight:'0.5em'}}>
                    {this.renderAddComponentDropdown(
                        <span key={"Eingabe"}><FontAwesomeIcon key={"Eingabe_Icon"} icon={faPlusSquare} /> Komponente hinzufügen</span>,
                        ComponentUtil.getEingabeKomponenten(),
                        ComponentUtil.getTextKomponenten()
                        )
                    }
                </div>
            </div>
        )
    }


    renderComponentsList(){
        if(this.section.components.length === 0){
            return <div className={"components empty"}>

                {this.sectionsSortable &&
                    <Droppable type={"inputDragables"} droppableId={"dad-section-"+this.sectionIndex}>
                        {(provided, snapshot) => (
                            <><div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className={snapshot.isDraggingOver?"componentsDropZone isDraggingOver":"componentsDropZone"}
                            >
                                {provided.placeholder}
                            </div>
                            </>
                        )}
                    </Droppable>
                }

            </div>
        }


        if(this.sectionsSortable){
            return(
                <div key={this.state.renderKey} className={"components"}>
                    <div>
                        <Droppable type={"inputDragables"} droppableId={"dad-section-"+this.sectionIndex}>
                            {(provided, snapshot) => (
                                <><div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className={snapshot.isDraggingOver?"componentsDropZone isDraggingOver":"componentsDropZone"}
                                >
                                    <div>
                                        {this.section.components.map( (component, index) => {
                                            return (<>
                                                <Draggable isDragDisabled={component.fromParent}
                                                            style={{width:"auto",border:'1px solid #333'}}
                                                           draggableId={"draggable-component-"+component.id} index={index}>
                                                    {function (provided, snapshot) {
                                                        return (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                className={`${'componentDraggable'} ${component.fromParent?'fromParent':' '} ${snapshot.isDragging?'isDragging':' '}`}
                                                                /*style={getItemStyle(snapshot.isDragging,provided.draggableProps.style)}*/
                                                            >
                                                                <div style={{width:"auto",background:'#f1f1f1',padding:'0.2em',marginBottom:'0.2em'}}>
                                                                   {component.title}
                                                                </div>
                                                            </div>
                                                        );
                                                    }}
                                                </Draggable>

                                                </>)
                                        })}
                                    </div>
                                    {provided.placeholder}
                                </div>
                                </>
                            )}
                        </Droppable>
                    </div>
                </div>
            )
        }else{
            return(
                <div className={"components"}>
                    <Row>
                        {this.section.components.map( (component, index) => {
                            return (
                                <Col xs={component.config.size?component.config.size:12} key={"DroppableCol" + Math.random().toString()}>
                                    {<DragableComponentBlock
                                        component={component}
                                        key={component.id}
                                        onRemoveComponentClicked={()=>this.removeComponent(index)}
                                        onComponentUpdate={this.onComponentUpdate}
                                        componentIndex={index} />}
                                </Col>)
                        })}
                    </Row>
                </div>
            )
        }


    }

    render() {
        let draggableDisabled = this.section.fromParent
        var self = this;
        if(self.sectionsSortable){
            return(
                <div>
                    <Draggable isDragDisabled={draggableDisabled} key={self.section.id} draggableId={"draggable-section-"+self.section.id} index={this.props.sectionIndex}>
                        {function (provided, snapshot) {
                            return (
                                <div
                                    onDragEnd={provided.onDragEnd}
                                    onDragStart={provided.draggableProps.onDragStart}
                                    {...provided.draggableProps}
                                    ref={provided.innerRef}
                                    {...provided.dragHandleProps}
                                    className={`${'sectionDraggable'} ${self.section.fromParent?'fromParent':' '} ${self.section.hide?'hided':' '} ${snapshot.isDragging?'isDragging':' '}`}
                                    /*style={getItemStyle(snapshot.isDragging,provided.draggableProps.style)}*/
                                >
                                    <h2>{self.section.title}</h2>
                                    {self.renderSectionAccording()}
                                </div>
                            );
                        }}
                    </Draggable>
                </div>

            )
        }else{
            return(
                <div className={this.section.fromParent?'sectionDraggable fromParent':'sectionDraggable'}>
                    {self.renderSectionAccording()}
                </div>
            )
        }
    }
}
export default DragableSectionBlock;