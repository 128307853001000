import React, {Component} from 'react';
import {Draggable, Droppable} from "react-beautiful-dnd";
const {v4 : uuidv4} = require('uuid')



const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 0.5,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "#f1f1f1",

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
});


class DragableRowsWrapper extends Component {
    constructor(props) {
        super(props);

        this.items = props.items ?? [];
        this.onUpdated = props.onUpdated;
        this.unique_id = props.id ?? uuidv4();
        let draggableItems = [];

        this.items.forEach((item) => {
            draggableItems.push({
                id: uuidv4(),
                content: item
            })
        })
        this.state = {
            items: props.items,
            draggableItems: draggableItems
        };
        let object = {
            data: props.items,
            onUpdatedEvent: this.onUpdated
        }
        DragableRowsWrapper._storeInGlobals(this.unique_id, object);
    }

    static _getFromLocalStorage(DragableRowsWrapperId){
        this._setupGlobalStorage();
        let eventObject = window.globals.DragableRowsWrapper[DragableRowsWrapperId];
        if(eventObject !== undefined){
            return eventObject;
        }else{
            return null;
        }
    }

    static fireOnUpdateReorderEvent(DragableRowsWrapperId, startIndex, endIndex){
        this._setupGlobalStorage();
        let eventObject = window.globals.DragableRowsWrapper[DragableRowsWrapperId];
        if(eventObject !== undefined){
            eventObject.onUpdatedEvent(startIndex,endIndex);
        }
    }

    static _setupGlobalStorage(){
        if(window.globals === undefined){
            window.globals = {};
        }
        if(window.globals.DragableRowsWrapper === undefined){
            window.globals.DragableRowsWrapper = {};
        }
        if(window.globals.DragableRowsWrapper[this.unique_id] === undefined){
            window.globals.DragableRowsWrapper[this.unique_id] = {};
        }
    }

    static _storeInGlobals(DragableRowsWrapperId, eventObject){
        this._setupGlobalStorage();
        window.globals.DragableRowsWrapper[DragableRowsWrapperId] = eventObject;
    }

    static reorderItems(items, startIndex, endindex){
        let itemsArray = Array.from(items);
        let [removed] = itemsArray.splice(startIndex, 1);
        itemsArray.splice(endindex, 0, removed);
        return itemsArray;
    }


    render() {
        return (
            <div style={{width:'100%'}}>
            <Droppable droppableId={this.unique_id}>
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                    >
                        {this.state.draggableItems.map((draggableItem, index) => (
                            <Draggable key={draggableItem.id} draggableId={draggableItem.id} index={index}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                        )}
                                    >
                                        {draggableItem.content}
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
            </div>
        );
    }
}
export default DragableRowsWrapper;